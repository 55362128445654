import { useEffect, useState } from 'react';
import { Style } from 'react-style-tag';
import Spinner from '../../components/common/Spinner';
import { Routes } from '../../components/common/UrlUtils';
import SortableTable from '../../components/SortableTable';
import ServerError from '../../components/states/ServerError';
import FontService from '../../services/FontService';
import { AlertMessage } from '../../utils/AlertMessage';
import { PageUtils } from '../PageUtils';
import { ConfirmationDialog } from '../../components/common/ConfirmationDialog';

const FontsPage = () => {
  const HEADERS = [
    { key: 'example', value: 'Example', sortable: false },
    { key: 'Title', value: 'Title', sortable: true },
    { key: 'Categories', value: 'Categories', sortable: false },
    { key: 'LastModified', value: 'Last Modified', sortable: false },
    { key: 'Active', value: 'Active', sortable: true },
    { key: 'Options', value: 'Options', sortable: false },
  ];
  const SEARCH_FIELDS = ['Title', 'Active'];
  const FIELD_ID = 'FontId';
  const PAGE_TITLE = 'Fonts';
  const ROUTE_CREATE = Routes.font.create;
  const ROUTE_EDIT = Routes.font.edit;
  const FIELD_SINGULAR = 'font';
  const FIELD_PLURAL = 'fonts';

  const [isServerError, setIsServerError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [sort, setSort] = useState({ by: 'Title', order: 'asc' });
  const [items, setItems] = useState([]);
  const [alertMessage, setAlertMessage] = useState({
    message: '',
    type: '',
  });

  useEffect(() => {

    if (isLoading) {
      fetchData();
      setIsLoading(false);
    }

    async function fetchData() {
      const result = await FontService.getAll();
      if (result.error) {
        setIsServerError(true);
      } else {
        result.data.forEach(item => {
          item.ui = { checked: false };
        });
        setItems(result.data);
      }
    }

  }, [items, sort, alertMessage, isServerError]);

  return (
    <>
      {PageUtils.getPageHeader(PAGE_TITLE, ROUTE_CREATE, FIELD_SINGULAR)}
      {AlertMessage.getContent(alertMessage)}
      {getPageContent()}
    </>
  );

  // @Override
  function getPageContent() {
    if (isLoading) {
      return (<Spinner />);
    }

    if (isServerError) {
      return (<ServerError />);
    }

    return (
      <div className="table-responsive js-index">
        <SortableTable
          tableHeader={getHeaderContent()}
          tableFooter={getFooterContent()}
          items={getTableItems()}
          onSearchChanged={onSearchHandler}
          onOrderChange={onOrderChangedHandler}
        />
      </div>
    );
  }

  // @Override
  function onSearchHandler(searchTerm) {
    setSearchTerm(searchTerm.trim().toLowerCase());
  }

  // @Override
  async function onOrderChangedHandler(initIndex, newIndex, id) {
    const result = await FontService.updateOrder(id, newIndex);
    const font = items.find(item => item[FIELD_ID] === id);
    if (result.error) {
      setAlertMessage(AlertMessage.getReorderItemErrorMessage(FIELD_SINGULAR, font.Title, newIndex, result));
      return;
    }
    setAlertMessage(AlertMessage.getReorderItemSuccessMessage(FIELD_SINGULAR, font.Title, newIndex));
  }

  // @Override
  async function onMultiDeleteHandler(event) {
    event.preventDefault();

    const deletedItems = items.filter(item => item.ui.checked);

    if (!ConfirmationDialog.openDeleteItemsConfirmationMessage(deletedItems.length, FIELD_SINGULAR, FIELD_PLURAL)) {
      return;
    }

    const deleteIds = deletedItems.map(item => item[FIELD_ID]);
    const result = await FontService.deleteAllById({ FontIds: deleteIds });
    if (result.error) {
      setAlertMessage(AlertMessage.getDeleteItemsErrorMessage(deleteIds.length, FIELD_SINGULAR, FIELD_PLURAL, result));
      return;
    }

    setAlertMessage(AlertMessage.getDeleteItemsSuccessMessage(deleteIds.length, FIELD_SINGULAR, FIELD_PLURAL));
    setItems(items.filter(item => !item.ui.checked));
  }

  // @Override
  function getTableItems() {
    return PageUtils.getSortedMatchedItems(items, SEARCH_FIELDS, searchTerm, sort)
      .map(item => {
        return {
          tableData: getItemContent(item),
          active: true,
          key: item[FIELD_ID],
        };
      });
  }

  // @Override
  function onItemSelectChange(item) {
    setItems(PageUtils.toggleSelectedItem(items, FIELD_ID, item[FIELD_ID]));
  }

  // @Override
  async function onDeleteItemHandler(event, eventItem) {
    event.preventDefault();
    if (!ConfirmationDialog.openDeleteItemConfirmationMessage(FIELD_SINGULAR, eventItem.Title)) {
      return;
    }

    const result = await FontService.deleteById(eventItem[FIELD_ID]);
    if (result.error) {
      setAlertMessage(AlertMessage.getDeleteItemErrorMessage(FIELD_SINGULAR, eventItem.Title, result));
      return;
    }

    setAlertMessage(AlertMessage.getDeleteItemSuccessMessage(FIELD_SINGULAR, eventItem.Title));
    setItems(items.filter(item => item[FIELD_ID] !== eventItem[FIELD_ID]));
  }

  // @Override
  function getHeaderContent() {
    return PageUtils.getSortableHeaderContent(HEADERS, sort, isAllChecked(), checkAllHandler, changeSort);
  }

  // @Override
  function changeSort(event) {
    event.preventDefault();
    const sortBy = event.target.getAttribute('sort-by');
    const order = sortBy === sort.by ? (sort.order === 'asc' ? 'desc' : 'asc') : 'asc';
    setSort({
      by: sortBy,
      order,
    });
  }

  // @Override
  function getFooterContent() {
    return PageUtils.getSortableFooterContent(HEADERS, isAllChecked(), sort, items, FIELD_PLURAL, checkAllHandler, changeSort, onMultiDeleteHandler);
  }

  // @Override
  function isAllChecked() {
    return PageUtils.isAllChecked(items, SEARCH_FIELDS, setAlertMessage, sort);
  }

  // @Override
  function checkAllHandler(event) {
    setItems(PageUtils.checkAllSortedMatchedItems(items, SEARCH_FIELDS, searchTerm, sort, event.target.checked));
  }

  // Custom
  function getItemContent(item) {
    return (
      <>
        <td>
          <input type="checkbox"
            data-id={item.FontId}
            data-title={item.Title}
            checked={item.ui.checked}
            onChange={event => onItemSelectChange(item)} />
        </td>
        <td>
          {getFontFamilyInline(item)}
        </td>
        <td>
          {item.Title}
        </td>
        <td>
          {item.Categories.map(c => c.Name).join(', ')}
        </td>
        <td>
          {item.LastModified} by {item.LastModifiedBy}
        </td>
        <td>
          <span aria-hidden="true" className={`glyphicon glyphicon-${item.Active ? 'ok' : 'remove'}`}></span>
          {item.Active ? ' Yes' : ' No'} {item.OriginalActiveState === 2 ? ' (limited)' : ''}
        </td>
        <td>
          <a className="btn btn-default btn-xs" href={`${ROUTE_EDIT}/${item[FIELD_ID]}`}>
            <span aria-hidden="true" className="glyphicon glyphicon-pencil"></span> edit</a>
          <a
            className="btn btn-default btn-xs"
            href="/#"
            data-title={item.Title}
            data-id={item.FontId}
            onClick={event => onDeleteItemHandler(event, item)}>
            <span aria-hidden="true" className="glyphicon glyphicon-trash"></span> delete
          </a>
        </td>
      </>
    );
  }

  // Custom
  function getFontFamilyInline(font) {
    if (!font.FontFiles || font.FontFiles.length === 0) {
      return (
        <div>
          <span aria-hidden="true" className="glyphicon glyphicon-ban-circle thumb-notfound"></span>
        </div>
      );
    }
    return (
      <div>
        <Style>
          {
            `@font-face {
              font-family: "${font.Title}";
              src: ${getFontUrls(font)};
              font-weight: 100;
              font-style: normal;
            }`
          }
        </Style>
        <span style={{ fontFamily: font.Title }}>The quick brown fox jumped over THE LAZY DOG.</span>
      </div>
    );
  }
};

function getFontUrls(font) {
  if (!font.FontFiles || font.FontFiles.length === 0) {
    return ('');
  }

  return (
    font.FontFiles.map(fontFile => `url("${fontFile.FilePath}")`).join(',')
  );
}

export default FontsPage;
