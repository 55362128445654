export const Login = ({ onLoginClickHandler }) => {

  function onLoginClick() {
    if (onLoginClickHandler) {
      onLoginClickHandler();
    }
  }

  return (
    <>
      <nav className="navbar navbar-inverse navbar-fixed-top">
        <div className="container-fluid">
          <div className="navbar-header">
            <button aria-controls="navbar" aria-expanded="false" className="navbar-toggle collapsed" data-target="#navbar" data-toggle="collapse" type="button">
              <span className="sr-only">Toggle navigation</span> <span className="icon-bar"></span>
              <span className="icon-bar"></span> <span className="icon-bar"></span>
            </button>
            <a className="navbar-brand" href="/">HEMA editor management</a>
          </div>
          <div className="navbar-collapse collapse" id="navbar">
            <ul className="nav navbar-nav navbar-right">
              <li>
                <p className="navbar-text pointer" onClick={onLoginClick}>Login</p>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};