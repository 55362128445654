export class ConfirmationDialog {

  static openConfirmationDialog(text) {
    return window.confirm(text);
  }

  static openDeleteItemsConfirmationMessage(numberOfDeletedItems, entitySingular, entityPlural) {
    return this.openConfirmationDialog(`Are you sure you want to delete ${numberOfDeletedItems} ${numberOfDeletedItems === 1 ? entitySingular : entityPlural}?`);
  }

  static openDeleteItemConfirmationMessage(entitySingular, label) {
    return this.openConfirmationDialog(`Are you sure you want to delete ${label ? 'the' : 'a'} ${entitySingular}${label ? ` ${label}` : ''}?`);
  }
}