import { useEffect, useState } from 'react';
import Spinner from '../../components/common/Spinner';
import ServerError from '../../components/states/ServerError';
import CategoryService from '../../services/CategoryService';
import FontColorService from '../../services/FontColorService';
import ProductTypeService from '../../services/ProductTypeService';
import MasterDataService from './../../services/MasterDataService';
import ShapeService from './../../services/ShapeService';
import { AlertMessage } from '../../utils/AlertMessage';
import { ConfirmationDialog } from '../../components/common/ConfirmationDialog';

export const MasterdataPage = () => {
  const CATEGORY_SINGULAR = 'category';
  const FONT_COLOR_SINGULAR = 'font color';
  const PRODUCT_TYPE_SINGULAR = 'product type';
  const SHAPE_SINGULAR = 'shape';
  // TODO: Reorder per data type
  const [isLoading, setIsLoading] = useState(true);
  const [isServerError, setIsServerError] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    message: '',
    type: '',
  });
  const [newItem, setNewItem] = useState({
    Category: { value: '', visible: false },
    FontColor: { value: '', visible: false },
    ProductType: { value: '', visible: false },
    Shape: { value: '', visible: false }
  });

  const [categories, setCategories] = useState([]);
  const [fontColors, setFontColors] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [shapes, setShapes] = useState([]);

  let dragItem = null;
  let dragItemIndex = -1;

  useEffect(() => {
    if (isLoading) {
      fetchData();
      setIsLoading();
    }

    async function fetchData() {
      const result = await MasterDataService.getAll();

      if (result.error) {
        setIsServerError(true);
      } else {
        setCategories(result.data.Categories.map(category => {
          return { ...category, inputValue: '', isEdit: false };
        }));

        setFontColors(result.data.FontColors.map(fontColor => {
          return { ...fontColor, inputValue: '', isEdit: false };
        }));

        setProductTypes(result.data.ProductTypes.map(productType => {
          return { ...productType, inputValue: '', isEdit: false };
        }));

        setShapes(result.data.Shapes.map(shape => {
          return { ...shape, inputValue: '', isEdit: false };
        }));
      }
    }
  }, [isLoading, newItem, categories, fontColors, productTypes, shapes, alertMessage]);

  return (
    <>
      <h2 className='sub-header'>Masterdata</h2>
      {AlertMessage.getContent(alertMessage)}
      <div className="col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2">
        <div className="form-horizontal">
          <ul className="list-group ui-reorder-container" container-name='Category'>
            <li className="list-group-item active">
              <div className="row">
                <div className="col-xs-8 col-md-8">Category</div>
                <div className="col-xs-4 col-md-4">
                  <div className="pull-right">
                    <a
                      className="btn btn-default btn-xs"
                      data-type="Category"
                      data-function="add"
                      href="/#"
                      onClick={addCategoryHandler}
                    >
                      <span aria-hidden="true" className="glyphicon glyphicon-plus"></span> add category
                    </a>
                  </div>
                </div>
              </div>
            </li>
            {getNewItemInputContent('Category')}
            {getCategoryItems()}
          </ul>
        </div>
      </div>
      <div className="col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2">
        <form className="form-horizontal">
          <ul className="list-group ui-reorder-container" container-name='FontColor'>
            <li className="list-group-item active">
              <div className="row">
                <div className="col-xs-8 col-md-8">Font colors</div>
                <div className="col-xs-4 col-md-4">
                  <div className="pull-right">
                    <a
                      className="btn btn-default btn-xs"
                      data-type="FontColor"
                      data-function="add"
                      href="/#"
                      onClick={addFontColorHandler}>
                      <span aria-hidden="true" className="glyphicon glyphicon-plus"></span>
                      add color
                    </a>
                  </div>
                </div>
              </div>
            </li>
            {getNewItemInputContent('FontColor')}
            {getFontColorItems()}
          </ul>
        </form>
      </div>

      <div className="col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2">
        <form className="form-horizontal">
          <ul className="list-group ui-reorder-container" container-name='ProductType'>
            <li className="list-group-item active">
              <div className="row">
                <div className="col-xs-8 col-md-8">Product Type</div>
                <div className="col-xs-4 col-md-4">
                  <div className="pull-right">
                    <a
                      className="btn btn-default btn-xs"
                      href="/#"
                      data-type="ProductType"
                      data-function="add"
                      onClick={addProductTypeHandler}>
                      <span
                        aria-hidden="true"
                        className="glyphicon glyphicon-plus">
                      </span>
                      add Product Type
                    </a>
                  </div>
                </div>
              </div>
            </li>
            {getNewItemInputContent('ProductType')}
            {getProductTypeItems()}
          </ul>
        </form>
      </div>

      <div className="col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2">
        <form className="form-horizontal">
          <ul className="list-group ui-reorder-container" container-name='Shape'>
            <li className="list-group-item active">
              <div className="row">
                <div className="col-xs-8 col-md-8">Shape</div>
                <div className="col-xs-4 col-md-4">
                  <div className="pull-right">
                    <a
                      className="btn btn-default btn-xs"
                      href="/#"
                      data-type="Shape"
                      data-function="add"
                      onClick={addShapeHandler}>
                      <span
                        aria-hidden="true"
                        className="glyphicon glyphicon-plus">
                      </span>
                      add shape
                    </a>
                  </div>
                </div>
              </div>
            </li>
            {getNewItemInputContent('Shape')}
            {getShapeItems()}
          </ul>
        </form>
      </div>
    </>
  );

  function getNewItemInputContent(fieldName) {
    if (!newItem[fieldName].visible) {
      return null;
    }

    return (
      <li className="list-group-item">
        <div className="row">
          <div className="col-xs-8 col-md-8">
            <input
              className="form-control"
              type="text"
              onInput={(event) => updateNewItemHandler(event, fieldName)}
            />
          </div>
          <div className="col-xs-4 col-md-4">
            <div className="pull-right">
              <a
                className="btn btn-primary btn-xs"
                href="/#"
                onClick={(event) => saveNewItem(event, fieldName)}>
                <span aria-hidden="true" className="glyphicon glyphicon-pencil"></span> save
              </a>
              <a
                className="btn btn-default btn-xs"
                href="/#"
                onClick={(event) => cancelNewItem(event, fieldName)}>
                <span aria-hidden="true" className="glyphicon glyphicon-remove"></span> cancel
              </a>
            </div>
          </div>
        </div>
      </li>
    );
  }

  function saveNewItem(event, fieldName) {
    event.preventDefault();
    if (fieldName === 'Category') {
      return saveNewCategoryHandler();
    }

    if (fieldName === 'FontColor') {
      return saveNewFontColorHandler();
    }

    if (fieldName === 'ProductType') {
      return saveNewProductTypeHandler();
    }

    if (fieldName === 'Shape') {
      return saveNewShapeHandler();
    }
  }

  function cancelNewItem(event, fieldName) {
    event.preventDefault();
    const copy = { ...newItem };
    copy[fieldName] = { value: '', visible: false };
    setNewItem(copy);
  }

  function updateNewItemHandler(event, fieldName) {
    const copy = { ...newItem };
    copy[fieldName].value = event.target.value.trim();
    setNewItem(copy);
  }

  async function saveNewCategoryHandler() {
    const categoryName = newItem.Category.value;
    resetNewItem('Category');

    const result = await CategoryService.save({ Name: categoryName });
    if (result.error) {
      setAlertMessage(AlertMessage.getSaveItemErrorMessage(CATEGORY_SINGULAR, categoryName, result));
      return;
    }

    setAlertMessage(AlertMessage.getSaveItemSuccessMessage(CATEGORY_SINGULAR, categoryName));
    setCategories([...categories, { CategoryId: result.data, Name: categoryName, inputValue: '', isEdit: false }]);
  }


  async function saveNewFontColorHandler() {
    const fontColor = newItem.FontColor.value;
    resetNewItem('FontColor');

    const result = await FontColorService.save({ HexColor: fontColor });
    if (result.error) {
      setAlertMessage(AlertMessage.getSaveItemErrorMessage(FONT_COLOR_SINGULAR, fontColor, result));
      return;
    }
    setFontColors([...fontColors, { FontcolorId: result.data, HexColor: fontColor, inputValue: '', isEdit: false }]);
    setAlertMessage(AlertMessage.getSaveItemSuccessMessage(FONT_COLOR_SINGULAR, fontColor));
  }


  async function saveNewProductTypeHandler() {
    const productType = newItem.ProductType.value.toLowerCase();
    resetNewItem('ProductType');


    const result = await ProductTypeService.save({ Name: productType });
    if (result.error) {
      setAlertMessage(AlertMessage.getSaveItemErrorMessage(PRODUCT_TYPE_SINGULAR, productType, result));
      return;
    }

    setProductTypes([...productTypes, { ProductTypeId: result.data, Name: productType, inputValue: '', isEdit: false }]);
    setAlertMessage(AlertMessage.getSaveItemSuccessMessage(PRODUCT_TYPE_SINGULAR, productType));
  }


  async function saveNewShapeHandler() {
    const shapeName = newItem.Shape.value;
    resetNewItem('Shape');

    const result = await ShapeService.save({ Name: shapeName });
    if (result.error) {
      setAlertMessage(AlertMessage.getSaveItemErrorMessage(SHAPE_SINGULAR, shapeName, result));
      return;
    }
    setShapes([...shapes, { ShapeId: result.data, Name: shapeName, inputValue: '', isEdit: false }]);
    setAlertMessage(AlertMessage.getSaveItemSuccessMessage(SHAPE_SINGULAR, shapeName));
  }

  function resetNewItem(fieldName) {
    const copy = { ...newItem };
    copy[fieldName] = {
      value: '',
      visible: false,
    };
    setNewItem(copy);
  }

  function getCategoryItems() {
    if (isLoading) {
      return (<Spinner />);
    }

    if (isServerError) {
      return (<ServerError />);
    }

    return categories.map((item, index) => {
      return (
        <li className="list-group-item ui-reorder ui-state-default"
          data-sortable={true}
          key={index}
          data-key={index}
          data-itemid={item.CategoryId}
          data-name={item.Name}
          draggable={true}
          onDragStart={onDragStartHandler}
          onDragOver={onDragOverHandler}
          onDragEnd={onDragEndHandler}
          group-name='Category'>
          <div className="row">
            <div className="col-xs-8 col-md-8">

              {
                item.isEdit ?
                  <input
                    className="form-control"
                    type="text"
                    defaultValue={item.Name}
                    onInput={(event) => updateCategoryChanges(event, item)}
                  />
                  :
                  <span className="no-action">{item.Name}</span>
              }
            </div>
            <div className="col-xs-4 col-md-4">
              <div className="pull-right">
                <a
                  className="btn btn-default btn-xs"
                  href="/#"
                  onClick={
                    item.isEdit ?
                      (event) => saveCategoryChanges(event, item)
                      :
                      (event) => setCategoryEditMode(event, item, true)
                  }>
                  <span
                    aria-hidden="true"
                    className="glyphicon glyphicon-pencil">
                  </span>
                  {item.isEdit ? ' save' : ' edit'}
                </a>
                {
                  item.isEdit ?
                    <a
                      className="btn btn-default btn-xs"
                      href="/#"
                      onClick={(event) => setCategoryEditMode(event, item, false)}>
                      <span className="glyphicon glyphicon-remove"></span>
                      {' cancel'}
                    </a>
                    :
                    <a
                      className="btn btn-default btn-xs"
                      href="/#"
                      onClick={(event) => onDeleteCategory(event, item)}>
                      <span
                        aria-hidden="true"
                        className="glyphicon glyphicon-trash">
                      </span>{' delete'}
                    </a>
                }
              </div>
            </div>
          </div>
        </li>
      );
    });
  }

  function getFontColorItems() {
    if (isLoading) {
      return (<Spinner />);
    }

    if (isServerError) {
      return (<ServerError />);
    }

    return fontColors.map((item, index) => {
      return (
        <li className="list-group-item ui-reorder ui-state-default"
          data-sortable={true}
          key={index}
          data-key={index}
          data-itemid={item.FontcolorId}
          data-name={item.HexColor}
          draggable={true}
          onDragStart={onDragStartHandler}
          onDragOver={onDragOverHandler}
          onDragEnd={onDragEndHandler}
          group-name='FontColor'>
          <div className="row">
            <div className="col-xs-8 col-md-8">
              {
                item.isEdit ?
                  <input
                    className="form-control"
                    type="text"
                    defaultValue={item.HexColor}
                    onInput={(event) => updateFontColorChanges(event, item)}
                  />
                  :
                  <>
                    <span
                      aria-hidden="true"
                      className="glyphicon glyphicon-tint no-action" style={{ color: '#' + item.HexColor }}></span>
                    #{item.HexColor}
                  </>
              }
            </div>
            <div className="col-xs-4 col-md-4">
              <div className="pull-right">
                <a
                  className="btn btn-default btn-xs"
                  href="/#"
                  onClick={
                    item.isEdit ?
                      (event) => saveFontColorChanges(event, item)
                      :
                      (event) => setFontColorEditMode(event, item, true)
                  }>
                  <span
                    aria-hidden="true"
                    className="glyphicon glyphicon-pencil">
                  </span>
                  {item.isEdit ? ' save' : ' edit'}
                </a>
                {item.isEdit ?
                  <a
                    className="btn btn-default btn-xs"
                    href="/#"
                    onClick={(event) => setFontColorEditMode(event, item, false)}>
                    <span className="glyphicon glyphicon-remove"></span>
                    {' cancel'}
                  </a>
                  :
                  <a
                    className="btn btn-default btn-xs"
                    href="/#"
                    onClick={(event) => onDeleteFontColor(event, item)}>
                    <span aria-hidden="true" className="glyphicon glyphicon-trash"></span>
                    {' delete'}
                  </a>
                }
              </div>
            </div>
          </div>
        </li>
      );
    });
  }

  function getProductTypeItems() {
    if (isLoading) {
      return (<Spinner />);
    }

    if (isServerError) {
      return (<ServerError />);
    }

    return productTypes.map((item, index) => {
      return (
        <li className="list-group-item ui-reorder"
          data-sortable={false}
          key={index}
          data-key={index}
          data-name={item.Name}
          draggable={false}
          onDragStart={onDragStartHandler}
          onDragOver={onDragOverHandler}
          onDragEnd={onDragEndHandler}
          group-name='ProductType'>
          <div className="row">
            <div className="col-xs-8 col-md-8">
              {
                item.isEdit ?
                  <input
                    className="form-control"
                    type="text"
                    defaultValue={item.Name}
                    onInput={(event) => updateProductTypeChanges(event, item)}
                  />
                  :
                  <span className='no-action'>{item.Name}</span>
              }
            </div>
            <div className="col-xs-4 col-md-4">
              <div className="pull-right">
                <a
                  className="btn btn-default btn-xs"
                  href="/#"
                  onClick={
                    item.isEdit ?
                      (event) => saveProductTypeChanges(event, item)
                      :
                      (event) => setProductTypeEditMode(event, item, true)
                  }>
                  <span aria-hidden="true"
                    className="glyphicon glyphicon-pencil">
                  </span>
                  {item.isEdit ? ' save' : ' edit'}
                </a>
                {item.isEdit ?
                  <a
                    className="btn btn-default btn-xs"
                    href="/#"
                    onClick={(event) => setProductTypeEditMode(event, item, false)}>
                    <span className="glyphicon glyphicon-remove"></span>
                    {' cancel'}
                  </a>
                  :
                  <a
                    className="btn btn-default btn-xs"
                    href="/#"
                    onClick={(event) => onDeleteProductType(event, item)}>
                    <span
                      aria-hidden="true"
                      className="glyphicon glyphicon-trash">
                    </span>
                    {' delete'}
                  </a>
                }
              </div>
            </div>
          </div>
        </li>
      );
    });
  }

  function getShapeItems() {
    if (isLoading) {
      return (<Spinner />);
    }

    if (isServerError) {
      return (<ServerError />);
    }

    return shapes.map((item, index) => {
      return (
        <li className="list-group-item ui-reorder"
          data-sortable={false}
          key={index}
          data-key={index}
          data-name={item.Name}
          draggable={false}
          onDragStart={onDragStartHandler}
          onDragOver={onDragOverHandler}
          onDragEnd={onDragEndHandler}
          group-name='Shape'>
          <div className="row">
            <div className="col-xs-8 col-md-8">
              {
                item.isEdit ?
                  <input
                    className="form-control"
                    type="text"
                    defaultValue={item.Name}
                    onInput={(event) => updateShapeChanges(event, item)}
                  />
                  :
                  <span className="no-action">{item.Name}</span>
              }
            </div>
            <div className="col-xs-4 col-md-4">
              <div className="pull-right">
                <a
                  className="btn btn-default btn-xs"
                  href="/#"
                  onClick={
                    item.isEdit ?
                      (event) => saveShapeChanges(event, item)
                      :
                      (event) => setShapeEditMode(event, item, true)
                  }
                >
                  <span
                    aria-hidden="true"
                    className="glyphicon glyphicon-pencil">
                  </span>
                  {item.isEdit ? ' save' : ' edit'}
                </a>
                {item.isEdit ?
                  <a
                    className="btn btn-default btn-xs"
                    href="/#"
                    onClick={(event) => setShapeEditMode(event, item, false)}>
                    <span className="glyphicon glyphicon-remove"></span>
                    {' cancel'}
                  </a>
                  :
                  <a
                    className="btn btn-default btn-xs"
                    href="/#"
                    onClick={(event) => onDeleteShape(event, item)}>
                    <span
                      aria-hidden="true"
                      className="glyphicon glyphicon-trash">
                    </span>
                    {' delete'}
                  </a>
                }
              </div>
            </div>
          </div>
        </li>
      );
    });
  }

  /**
   * Category Handlers
   */
  function addCategoryHandler(event) {
    event.preventDefault();
    const copy = { ...newItem };
    copy.Category = { value: '', visible: true };
    setNewItem(copy);
  }

  function updateCategoryChanges(event, item) {
    event.preventDefault();
    const copyCategories = [...categories];
    const category = copyCategories.find(category => category.CategoryId === item.CategoryId);
    if (!category) {
      return;
    }

    category.inputValue = event.target.value.trim();
    setCategories(copyCategories);
  }

  async function saveCategoryChanges(event, item) {
    event.preventDefault();
    const copyCategories = [...categories];
    const category = copyCategories.find(category => category.CategoryId === item.CategoryId);
    if (!category) {
      return;
    }

    category.isEdit = false;
    const result = await CategoryService.update(category.CategoryId, { Name: category.inputValue });
    if (result.error) {
      setAlertMessage(AlertMessage.getSaveItemErrorMessage(CATEGORY_SINGULAR, category.inputValue, result));
      return;
    }

    setAlertMessage(AlertMessage.getSaveItemSuccessMessage(CATEGORY_SINGULAR, category.inputValue));
    category.Name = category.inputValue;
    setCategories(copyCategories);
  }

  function setCategoryEditMode(event, item, isEdit) {
    event.preventDefault();
    const copyCategories = [...categories];
    const category = copyCategories.find(category => category.CategoryId === item.CategoryId);
    if (!category) {
      return;
    }

    category.isEdit = isEdit;
    category.inputValue = category.Name;
    setCategories(copyCategories);
  }

  async function onDeleteCategory(event, item) {
    event.preventDefault();
    if (!ConfirmationDialog.openDeleteItemConfirmationMessage(CATEGORY_SINGULAR, item.Name)) {
      return;
    }

    const result = await CategoryService.deleteById(item.CategoryId);
    if (result.error) {
      setAlertMessage(AlertMessage.getDeleteItemErrorMessage(CATEGORY_SINGULAR, item.Name, result));
      return;
    }

    setAlertMessage(AlertMessage.getDeleteItemSuccessMessage(CATEGORY_SINGULAR, item.Name));
    setCategories(categories.filter(category => category.CategoryId !== item.CategoryId));
  }

  /**
   * FontColor Handlers
   */
  function addFontColorHandler(event) {
    event.preventDefault();
    const copy = { ...newItem };
    copy.FontColor = { value: '', visible: true };
    setNewItem(copy);
  }

  function updateFontColorChanges(event, item) {
    event.preventDefault();
    const copyFontColors = [...fontColors];
    const fontColor = copyFontColors.find(f => f.FontcolorId === item.FontcolorId);
    if (!fontColor) {
      return;
    }

    fontColor.inputValue = event.target.value.trim();
    setFontColors(copyFontColors);
  }

  async function saveFontColorChanges(event, item) {
    event.preventDefault();
    const copyFontColors = [...fontColors];
    const fontColor = copyFontColors.find(f => f.FontcolorId === item.FontcolorId);
    if (!fontColor) {
      return;
    }

    fontColor.isEdit = false;

    const result = await FontColorService.update(fontColor.FontcolorId, { HexColor: fontColor.inputValue });
    if (result.error) {
      setAlertMessage(AlertMessage.getSaveItemErrorMessage(FONT_COLOR_SINGULAR, fontColor.HexColor, result));
      return;
    }

    setAlertMessage(AlertMessage.getSaveItemSuccessMessage(FONT_COLOR_SINGULAR, fontColor.HexColor));
    fontColor.HexColor = fontColor.inputValue;
    setFontColors(copyFontColors);
  }

  function setFontColorEditMode(event, item, isEdit) {
    event.preventDefault();
    const copyFontColors = [...fontColors];
    const fontColor = copyFontColors.find(f => f.FontcolorId === item.FontcolorId);
    if (!fontColor) {
      return;
    }

    fontColor.isEdit = isEdit;
    fontColor.inputValue = fontColor.HexColor;
    setFontColors(copyFontColors);
  }

  async function onDeleteFontColor(event, item) {
    event.preventDefault();
    if (!ConfirmationDialog.openDeleteItemConfirmationMessage(FONT_COLOR_SINGULAR, item.HexColor)) {
      return;
    }

    const result = await FontColorService.deleteById(item.FontcolorId);
    if (result.error) {
      setAlertMessage(AlertMessage.getDeleteItemErrorMessage(FONT_COLOR_SINGULAR, item.HexColor, result));
      return;
    }

    setAlertMessage(AlertMessage.getDeleteItemSuccessMessage(FONT_COLOR_SINGULAR, item.HexColor));
    setFontColors(fontColors.filter(fontColor => fontColor.FontcolorId !== item.FontcolorId));
  }

  /**
   * ProductType Handlers
   */
  function addProductTypeHandler(event) {
    event.preventDefault();
    const copy = { ...newItem };
    copy.ProductType = { value: '', visible: true };
    setNewItem(copy);
  }

  function updateProductTypeChanges(event, item) {
    event.preventDefault();
    const copyProductTypes = [...productTypes];
    const productType = copyProductTypes.find(p => p.ProductTypeId === item.ProductTypeId);
    if (!productType) {
      return;
    }

    productType.inputValue = event.target.value.trim();
    setProductTypes(copyProductTypes);
  }

  async function saveProductTypeChanges(event, item) {
    event.preventDefault();
    const copyProductTypes = [...productTypes];
    const productType = copyProductTypes.find(p => p.ProductTypeId === item.ProductTypeId);
    if (!productType) {
      return;
    }

    productType.inputValue = productType.inputValue.toLowerCase();
    const request = { Name: productType.inputValue };
    productType.isEdit = false;

    const result = await ProductTypeService.update(item.ProductTypeId, request);
    if (result.error) {
      setAlertMessage(AlertMessage.getSaveItemErrorMessage(PRODUCT_TYPE_SINGULAR, productType.inputValue, result));
      return;
    }

    setAlertMessage(AlertMessage.getSaveItemSuccessMessage(PRODUCT_TYPE_SINGULAR, productType.inputValue));
    productType.Name = productType.inputValue;
    setProductTypes(copyProductTypes);
  }

  function setProductTypeEditMode(event, item, isEdit) {
    event.preventDefault();
    const copyProductTypes = [...productTypes];
    const productType = copyProductTypes.find(p => p.ProductTypeId === item.ProductTypeId);
    if (!productType) {
      return;
    }

    productType.inputValue = productType.Name;
    productType.isEdit = isEdit;
    setProductTypes(copyProductTypes);
  }

  async function onDeleteProductType(event, item) {
    event.preventDefault();
    if (!ConfirmationDialog.openDeleteItemConfirmationMessage(PRODUCT_TYPE_SINGULAR, item.Name)) {
      return;
    }

    const result = await ProductTypeService.deleteById(item.ProductTypeId);
    if (result.error) {
      setAlertMessage(AlertMessage.getDeleteItemErrorMessage(PRODUCT_TYPE_SINGULAR, item.Name, result));
      return;
    }

    setAlertMessage(AlertMessage.getDeleteItemSuccessMessage(PRODUCT_TYPE_SINGULAR, item.Name));
    setProductTypes(productTypes.filter(p => p.ProductTypeId !== item.ProductTypeId));
  }

  /**
   * Shape Handler
   */
  function addShapeHandler(event) {
    event.preventDefault();
    const copy = { ...newItem };
    copy.Shape = { value: '', visible: true };
    setNewItem(copy);
  }

  function updateShapeChanges(event, item) {
    event.preventDefault();
    const copyShapes = [...shapes];
    const shape = copyShapes.find(s => s.ShapeId === item.ShapeId);
    if (!shape) {
      return;
    }

    shape.inputValue = event.target.value.trim();
    setShapes(copyShapes);

  }

  async function saveShapeChanges(event, item) {
    event.preventDefault();
    const copyShapes = [...shapes];
    const shape = copyShapes.find(s => s.ShapeId === item.ShapeId);
    if (!shape) {
      return;
    }

    shape.isEdit = false;

    const result = await ShapeService.update(shape.ShapeId, { Name: shape.inputValue });
    if (result.error) {
      setAlertMessage(AlertMessage.getSaveItemErrorMessage(SHAPE_SINGULAR, item.inputValue, result));
      return;
    }

    setAlertMessage(AlertMessage.getSaveItemSuccessMessage(SHAPE_SINGULAR, item.inputValue));
    shape.Name = shape.inputValue;
    setShapes(copyShapes);
  }

  function setShapeEditMode(event, item, isEdit) {
    event.preventDefault();
    const copyShapes = [...shapes];
    const shape = copyShapes.find(s => s.ShapeId === item.ShapeId);
    if (!shape) {
      return;
    }

    shape.inputValue = shape.Name;
    shape.isEdit = isEdit;
    setShapes(copyShapes);
  }

  async function onDeleteShape(event, item) {
    event.preventDefault();
    if (!ConfirmationDialog.openDeleteItemConfirmationMessage(SHAPE_SINGULAR, item.Name)) {
      return;
    }

    const result = await ShapeService.deleteById(item.ShapeId);
    if (result.error) {
      setAlertMessage(AlertMessage.getDeleteItemErrorMessage(SHAPE_SINGULAR, item.Name, result));
      return;
    }

    setShapes(shapes.filter(shape => shape.ShapeId !== item.ShapeId));
    setAlertMessage(AlertMessage.getDeleteItemSuccessMessage(SHAPE_SINGULAR, item.Name));
  }

  function onDragStartHandler(event) {
    const element = event.target.closest('li');
    if (!element || !element.hasAttribute('data-sortable')) {
      return;
    }

    dragItem = element;

    dragItemIndex = findIndexForKey(dragItem.dataset.key);

    event.dataTransfer.effectAllowed = 'grabbing';
    event.dataTransfer.dropEffect = 'grabbing';

    dragItem.setAttribute('placeholder', 'placeholder');

    // Firefox requires calling dataTransfer.setData
    // for the drag to properly work
    event.dataTransfer.setData('text/html', event.target);
  }

  async function onDragEndHandler(event) {
    if (!dragItem) {
      return;
    }

    dragItem.removeAttribute('placeholder');
    const index = findIndexForKey(dragItem.dataset.key);
    const itemId = dragItem.dataset.itemid;
    const groupName = dragItem.getAttribute('group-name');
    const itemName = dragItem.dataset.name;

    if (dragItemIndex.toString() === index.toString()) {
      return;
    }

    dragItem = null;
    dragItemIndex = -1;

    const result = await MasterDataService.updateOrder(itemId, index, groupName);
    debugger;
    if (result.error) {
      setAlertMessage(AlertMessage.getReorderItemErrorMessage(groupName, itemName, index, result));
      return;
    }

    setAlertMessage(AlertMessage.getReorderItemSuccessMessage(groupName, itemName, index));
  }

  // Key must be passed as string since it's DOM value
  function findIndexForKey(key) {
    const table = dragItem.closest(`[container-name='${dragItem.getAttribute('group-name')}']`);
    // const table = document.getElementById('sortable');
    const index = Array.from(table.childNodes).findIndex((node) => node.dataset.key === key);

    // Remove the header index
    return index - 1;
  }

  function onDragOverHandler(event) {
    event.preventDefault();

    event.dataTransfer.effectAllowed = 'grabbing';
    event.dataTransfer.dropEffect = 'grabbing';

    const li = event.target.closest('li');
    if (!li || li.hasAttribute('placeholder') || !li.hasAttribute('data-sortable')) {
      return;
    }

    const parent = event.target.closest('.ui-reorder-container');
    if (parent.getAttribute('container-name') !== dragItem.getAttribute('group-name')) {
      return;
    }

    // const parent = dragItem.closest(`[container-name='${dragItem.getAttribute('group-name')}']`);//document.getElementById('sortable');
    if (parent) {
      const children = Array.from(parent.childNodes);
      if (children.indexOf(dragItem) > children.indexOf(li)) {
        li.before(dragItem);
      } else {
        li.after(dragItem);
      }
    }
  }
};
