import { useLocation } from 'react-router-dom';
import styles from '../styles/pages/NotFound.module.scss';
import '../styles/pages/styles.scss';

function NotFound() {
  let location = useLocation();

  function goToHomepage() {
    window.open('/', '_self');
  }

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.errorCode}>
          404
        </div>
        <div className={styles.errorMessage}>
          <div>
            The page
            &nbsp;<span className={styles.page}>{location.pathname}</span>&nbsp;
            not found
          </div>
        </div>
        <div className={styles.backBottom}>
          <div className={styles.wrapper}>
            <button onClick={goToHomepage}>
              Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
