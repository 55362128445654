import { DELETE, GET, POST, PUT } from './ApiService';
import { getCategoriesMock } from './mocks/CategoryServiceMock';
import appConfig from '../config/app-config';

const baseUrl = `${appConfig.baseUri}/management/v1/Categories`;
export default class CategoryService {

  static async getAll() {
    if (appConfig.isTest) {
      return { data: getCategoriesMock(), error: false };
    }

    return GET({
      url: baseUrl,
      token: true,
    });
  }

  static async getById(id) {
    return GET({
      url: `${baseUrl}/${id}`,
      token: true,
    });
  }

  static async save(request) {
    return POST({
      url: baseUrl,
      token: true,
      body: request,
    });
  }

  static async update(id, request) {
    return PUT({
      url: `${baseUrl}/${id}`,
      token: true,
      body: request,
    });
  }

  static async deleteById(id) {
    return DELETE({
      url: `${baseUrl}/${id}`,
      token: true,
    });
  }
}
