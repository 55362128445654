import { MsalSingleton } from '..';
import appConfig from '../config/app-config';

export const GET = async (requestConfig) => {
  requestConfig.method = 'GET';
  return _fetch(requestConfig);
};

export const POST = async (requestConfig) => {
  requestConfig.method = 'POST';
  return _fetch(requestConfig);
};

export const PUT = async (requestConfig) => {
  requestConfig.method = 'PUT';
  return _fetch(requestConfig);
};

export const DELETE = async (requestConfig) => {
  requestConfig.method = 'DELETE';
  return _fetch(requestConfig);
};

async function _fetch(requestConfig) {
  requestConfig.url = appConfig.apiServer + requestConfig.url;
  const request = await _extendHeaders(requestConfig);
  try {
    const response = await fetch(requestConfig.url, request);

    try {
      const data = await response.json();
      return { data, error: !response.status.toString().startsWith('2') };
    } catch (error) {
      return { data: response, error: !response.status.toString().startsWith('2') };
    }

  } catch (error) {
    return { data: error, error: true };
  }
};

async function _extendHeaders(requestConfig) {
  const innerHeader = {
    // 'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Request-Method': requestConfig.method,
    // ...requestConfig.headers
  };

  // do not set content type header for formdata, this should be set automatically
  if (!requestConfig.body || !requestConfig.body.constructor || requestConfig.body.constructor.name !== 'FormData') {
    innerHeader['Content-Type'] = 'application/json';
  }

  const request = {
    method: requestConfig.method,
    headers: innerHeader,
    'Access-Control-Request-Method': requestConfig.method,
  };

  if (requestConfig.token) {
    const token = await getBearerToken();
    request.headers.Authorization = 'Bearer ' + token;
  }


  if (requestConfig.baseAuth) {
    const { username, password } = requestConfig.baseAuth;
    request.headers.Authorization = 'Basic ' + btoa(username + ':' + password);
  }

  if (requestConfig.body) {
    request.body = requestConfig.body.constructor.name === 'FormData' ? requestConfig.body : JSON.stringify(requestConfig.body);
  }

  return request;
};


async function getBearerToken() {
  const token = JSON.parse(localStorage.getItem('custom_editor_token') || '{}');
  const now = new Date();
  if (token && token.expires_in >= now.getTime()) {
    return token.access_token;
  }

  let msal = null;
  try {
    msal = await MsalSingleton.getMsalInstance().acquireTokenSilent(loginRequest);
  } catch (error) {
    try {
      msal = await MsalSingleton.getMsalInstance().acquireTokenPopup(loginRequest);
    } catch (error2) {
      window.location.href = '/';
    }

  }
  const body = new URLSearchParams({
    subject_token: msal.idToken,
    grant_type: 'urn:ietf:params:oauth:grant-type:token-exchange',
    client_id: appConfig.hema.clientId,
    client_secret: appConfig.hema.clientSecret,
  });

  const options = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    body: body,
    url: `${appConfig.hema.securityEndpoint}/token`,
  };

  const response = await fetch(options.url, options);
  const result = await response.json();
  const expireDateTime = now.getTime() + result.expires_in * 1000 - 5000; // five second margin
  localStorage.setItem('custom_editor_token', JSON.stringify({...result, expires_in: expireDateTime}));
  return result.access_token;
}

const loginRequest = {
  scopes: ['User.Read']
};
