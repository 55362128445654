import { Route, Switch } from 'react-router-dom';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import PageHolder from './pages/PageHolder';
import { Routes } from './components/common/UrlUtils';
import StickersPage from './pages/sticker/StickersPage';
import StickerUpsertPage from './pages/sticker/StickerUpsertPage';
import ProductsPage from './pages/product/ProductsPage';
import ProductUpsertPage from './pages/product/ProductUpsertPage';
import LayoutTemplatesPage from './pages/layout-template/LayoutTemplatesPage';
import { LayoutUpsertPage } from './pages/layout-template/LayoutUpsertPage';
import { MasterdataPage } from './pages/MasterdataPage/MasterdataPage';
import { FontUpsertPage } from './pages/font/FontUpsertPage';
import { EditorOptionsPage } from './pages/editor-options/EditorOptionsPage';
import { BackgroundUpsertPage } from './pages/background/BackgroundUpsertPage';
import { FilterUpsertPage } from './pages/editor-options/FilterUpsertPage';
import BackgroundsPage from './pages/background/BackgroundsPage';
import FontsPage from './pages/font/FontsPage';
import { useMsal } from '@azure/msal-react';
import { loginRequest, msalConfig } from './authConfig';
import { Login } from './pages/Login';
import { InteractionRequiredAuthError } from '@azure/msal-common';

function App() {
  const { instance, accounts } = useMsal();

  function getPrivateRoutes() {
    return (
      <Switch>
        <Route exact path='/'>
          <PageHolder page={<Home />} />
        </Route>
        <Route exact path={Routes.sticker.index}>
          <PageHolder page={<StickersPage />} title={'Index'} />
        </Route>
        <Route exact path={Routes.sticker.create}>
          <PageHolder page={<StickerUpsertPage />} title={'Create'} />
        </Route>
        <Route exact path={`${Routes.sticker.edit}/:stickerId`}>
          <PageHolder page={<StickerUpsertPage />} title={'Edit'} />
        </Route>

        <Route exact path={Routes.background.index}>
          <PageHolder page={<BackgroundsPage />} title={'Index'} />
        </Route>
        <Route exact path={Routes.background.create}>
          <PageHolder page={<BackgroundUpsertPage />} title={'Index'} />
        </Route>
        <Route exact path={`${Routes.background.edit}/:backgroundId`}>
          <PageHolder page={<BackgroundUpsertPage />} title={'Create'} />
        </Route>

        <Route exact path={Routes.font.index}>
          <PageHolder page={<FontsPage />} title={'Index'} />
        </Route>
        <Route exact path={`${Routes.font.edit}/:fontId`}>
          <PageHolder page={<FontUpsertPage />} title={'Edit'} />
        </Route>
        <Route exact path={Routes.font.create}>
          <PageHolder page={<FontUpsertPage />} title={'Create'} />
        </Route>

        <Route exact path={Routes.product.index}>
          <PageHolder page={<ProductsPage />} title={'Product management'} />
        </Route>
        <Route exact path={Routes.product.create}>
          <PageHolder page={<ProductUpsertPage />} title={'Product - Create'} />
        </Route>
        <Route exact path={`${Routes.product.edit}/:productId`}>
          <PageHolder page={<ProductUpsertPage />} title={'Product - Edit'} />
        </Route>

        <Route exact path={Routes.layout.index}>
          <PageHolder page={<LayoutTemplatesPage />} title={'Layout Templates'} />
        </Route>
        <Route exact path={Routes.layout.create}>
          <PageHolder page={<LayoutUpsertPage />} title={'Layout Templates - Add'} />
        </Route>
        <Route exact path={`${Routes.layout.edit}/:layoutId`}>
          <PageHolder page={<LayoutUpsertPage />} title={'Layout Templates - Edit'} />
        </Route>

        <Route exact path={Routes.masterdata.index}>
          <PageHolder page={<MasterdataPage />} title={'Masterdata'} />
        </Route>


        <Route exact path={Routes.editorOption.index}>
          <PageHolder page={<EditorOptionsPage />} title={'Editor Options'} />
        </Route>
        <Route exact path={Routes.editorOption.create}>
          <PageHolder page={<FilterUpsertPage />} title={'Filter - Add'} />
        </Route>
        <Route exact path={`${Routes.editorOption.edit}/:filterId`}>
          <PageHolder page={<FilterUpsertPage />} title={'Filter - Edit'} />
        </Route>

        <Route path='*'>
          <NotFound />
        </Route>
      </Switch>
    );
  }

  async function handleMsalLogin() {
    // Set a new redirectUri if a user did not enter the root URL
    const msalConfigCopy = { ...msalConfig };
    msalConfigCopy.auth.redirectUri = window.location.href;

    try {
      const tokenResponse = await instance.acquireTokenSilent(loginRequest);
      if (tokenResponse) {
        console.log(tokenResponse);
        localStorage.setItem('custom_editor_msal', JSON.stringify(tokenResponse));
        instance.setActiveAccount(tokenResponse.account);

        return tokenResponse;
      }
    } catch (error) {
      const isinstance = error instanceof InteractionRequiredAuthError;
      console.warn('silent token acquisition fails. acquiring token using popup');

      try {
        const tokenResponse = await instance.acquireTokenPopup(loginRequest);
        instance.setActiveAccount(tokenResponse.account);
        localStorage.setItem('custom_editor_user', tokenResponse.account.name);
        return tokenResponse;
      } catch (error) {
        console.warn(error);
      }
    }
  };

  function getTemplate() {
    if (accounts.length) {
      return getPrivateRoutes();
    }

    return (<Login onLoginClickHandler={handleMsalLogin} />);
  }

  return getTemplate();
}

export default App;
