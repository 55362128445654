export class PageUtils {

  // UI
  static getPageHeader(pageTitle, createRoute, createField) {
    const title = pageTitle[0].toUpperCase() + pageTitle.toLowerCase().substring(1);
    return (
      <h2 className="sub-header">{title} <a className="btn btn-primary btn-sm" href={createRoute}>
        <span aria-hidden="true" className="glyphicon glyphicon-plus"></span> Add a new {createField}</a>
      </h2>
    );
  }

  static getUpsertPageHeader(pageTitle, isEdit, indexRoute) {
    let title = [];
    pageTitle.split(' ').forEach(word => {
      title.push(word[0].toUpperCase() + word.toLowerCase().substring(1));
    });
    
    title = title.join(' ');
    return (
      <h2 className='sub-header'>{title} - {isEdit ? 'Edit' : 'Add'} <a className="btn btn-default btn-xs" href={indexRoute}>
        <span aria-hidden="true" className="glyphicon glyphicon-arrow-left"></span> back</a>
      </h2>
    );
  }

  // Items Utils
  static getSortedMatchedItems(items, searchableFields, searchTerm, sort) {
    const filteredItems = searchTerm ?
      items.filter(item => this.isSearchTermMatched(item, searchableFields, searchTerm))
      :
      [...items];

    return filteredItems.sort((item1, item2) => sortItems(item1, item2, sort.by, sort.order));
  }

  static isSearchTermMatched(item, searchableFields, searchTerm) {
    return searchableFields.some(field => {
      if (Array.isArray(item[field])) {
        const result = item[field].some(arrayItem => arrayItem.toString().toLowerCase().includes(searchTerm));
        return result;
      }

      return item[field] && item[field].toString().toLowerCase().includes(searchTerm);
    });
  }

  static toggleSelectedItem(items, fieldId, itemId) {
    const copyItems = [...items];
    const result = copyItems.find(copyItem => copyItem[fieldId] === itemId);
    if (result) {
      result.ui.checked = !result.ui.checked;
    }
    return copyItems;
  }

  static checkAllSortedMatchedItems(items, searchableFields, searchTerm, sort, isChecked) {
    const copy = [...items];
    copy.forEach(item => {
      if (this.isSearchTermMatched(item, searchableFields, searchTerm)) {
        item.ui.checked = isChecked;
      }
    });
    return copy.sort((item1, item2) => sortItems(item1, item2, sort.by, sort.order));
  }

  // Sortable Table Utils
  static getSortableHeaderContent(headers, sort, isAllChecked, onCheckAllHandler, onChangeSort) {
    return (
      <thead key={'header_content'}>
        <tr key={'header_content_tr'}>
          <th>
            <input
              key={'check_all'}
              type='checkbox'
              className='check-all'
              checked={isAllChecked}
              onChange={onCheckAllHandler} />
          </th>
          {
            headers.map(header => {
              let sortable = header.sortable ? this.getSortItem(header.key, sort) : 'none';
              return (
                <th
                  className={header.sortable ? 'ui-sortable' : ''}
                  sort={sortable}
                  sort-by={header.key}
                  key={header.key}
                  onClick={(event) => onChangeSort(event)}>
                  {header.value}
                </th>
              );
            })
          }
        </tr>
      </thead>
    );
  }

  static getSortableFooterContent(headers, isAllChecked, sort, items, fieldPlural, onCheckAllHandler, onChangeSort, onMultiDeleteHandler) {
    return (
      <tfoot>
        <tr>
          <th>
            <input
              key={'check_all'}
              type='checkbox'
              className='check-all'
              checked={isAllChecked}
              onChange={onCheckAllHandler} />
          </th>
          {
            headers.map(header => {
              let sortable = header.sortable ? this.getSortItem(header.key, sort) : 'none';
              return (
                <th
                  className={header.sortable ? 'ui-sortable' : ''}
                  sort={sortable}
                  sort-by={header.key}
                  key={header.key}
                  onClick={(event) => onChangeSort(event)}>
                  {header.value}
                </th>
              );
            })
          }
        </tr>
        <tr>
          <th className='no-sort' colSpan={headers.length + 1}>
            <a
              className={`btn btn-default btn-sm ${items.some(item => item.ui.checked) ? '' : 'disabled'}`}
              onClick={onMultiDeleteHandler}>
              Delete selected {fieldPlural}
            </a>
          </th>
        </tr>
      </tfoot>
    );
  }

  static isAllChecked(items, searchableFields, searchTerm, sort) {
    const filteredItems = this.getSortedMatchedItems(items, searchableFields, searchTerm, sort);
    if (!filteredItems.length) {
      return false;
    }

    return !filteredItems.some(item => !item.ui.checked);
  }

  static getSortItem(headerKey, sort) {
    if (headerKey === sort.by) {
      return sort.order;
    }
    return 'none';
  }


  ///////////////
  static getMatchedItems(items, searchableFields, searchTerm) {
    if (!searchTerm) {
      return [...items];
    }

    return items.filter(item => {
      return searchableFields.some(field => {
        if (Array.isArray(item[field])) {
          const result = item[field].some(arrayItem => arrayItem.toString().toLowerCase().includes(searchTerm));
          return result;
        }

        return item[field] && item[field].toString().toLowerCase().includes(searchTerm);
      });
    });
  }

  static sortItems(items, sort) {
    return items.sort((item1, item2) => sortItems(item1, item2, sort.by, sort.order));
  }

}

function sortItems(item1, item2, sortBy, sortOrder) {
  let compare1 = item1[sortBy];
  let compare2 = item2[sortBy];

  if (compare1 === null || compare1 === undefined || !compare1.toString().trim()) {
    return -1;
  }

  if (!compare2 === null || compare2 === undefined || !compare2.toString().trim()) {
    return -1;
  }

  compare1 = item1[sortBy].toString().toLowerCase();
  compare2 = item2[sortBy].toString().toLowerCase();
  const value = sortOrder === 'asc' ? 1 : -1;

  if (compare1 < compare2) {
    return value * -1;
  }
  if (compare2 < compare1) {
    return value;
  }
  return 0;
}