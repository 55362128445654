export const TextValuePairOptions = ({ items, selectedValues = [] }) => {

  return (
    getContent()
  );

  function getContent() {
    if (!items || !items.length) {
      return null;
    }

    return (
      <>
        {getOptions()}
      </>
    );
  };

  function getOptions() {
    let emptyPlaceholder = null;
    if (!selectedValues.length) {
      emptyPlaceholder = (
        <option
          disabled={true}
          value=""
          selected={true}>
        </option>
      );
    }
    return [
      emptyPlaceholder,
      ...items.map(item => {
        let isSelected = false;
        try {
          isSelected = selectedValues.some(value => item.value.toString() === value.toString());
        } catch (error) {
          console.error(error);
          console.error({ items, selectedValues });
        }
        return (
          <option
            key={item.value}
            data-val={item.value}
            value={item.value}
            selected={isSelected}>
            {item.text}
          </option>
        );
      })
    ];
  }
};
