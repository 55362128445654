import React, { useState, useEffect } from 'react';
import { Routes } from './../components/common/UrlUtils';
import { getLoggedUserName } from '../utils/authUtils';
import { MsalSingleton } from '..';

const BAR_TYPE = {
  MENU: 'MENU',
  USER: 'USER',
  NONE: 'NONE',
};
const PageHolder = ({ page, title }) => {
  const [menuStatus, setMenuStatus] = useState(BAR_TYPE.NONE);

  useEffect(() => {
    const fullTitle = `${title ? title + ' - ' : ''}HEMA editor management`;
    const event = new CustomEvent('titlechange', { detail: fullTitle });
    document.dispatchEvent(event);
  }, [menuStatus]);

  function getActiveTabCss(tabName) {
    if (window.location.pathname.toLowerCase().includes(tabName)) {
      return 'active';
    }
    return null;
  }

  return (
    <>
      <nav className="navbar navbar-inverse navbar-fixed-top">
        <div className="container-fluid">
          <div className="navbar-header">
            <button aria-controls="navbar" aria-expanded="false" className="navbar-toggle collapsed" data-target="#navbar" data-toggle="collapse" type="button">
              <span className="sr-only">Toggle navigation</span> <span className="icon-bar"></span>
              <span className="icon-bar"></span> <span className="icon-bar"></span>
            </button>
            <a className="navbar-brand" href="/">HEMA editor management</a>
          </div>
          <div className="navbar-collapse collapse">
            {getNavBarContent(getLoggedUserName(), getUserDropdownContent(), BAR_TYPE.USER)}
            {getNavBarContent('Menu', getDropdownMenu(), BAR_TYPE.MENU)}
          </div>
        </div>
      </nav>
      <div className="container-fluid">
        <div className="row">
          {getSideMainMenu()}
          <div className="col-sm-9 col-sm-offset-3 col-md-10 col-md-offset-2 main">
            {page}
          </div>
        </div >
      </div >
    </>
  );

  function setMenuBarStatus(event, menuType) {
    event.preventDefault();
    if (menuStatus === menuType) {
      setMenuStatus(BAR_TYPE.NONE);
      return;
    }

    setMenuStatus(menuType);
  }

  function getNavBarContent(label, dropdownContent, menuType) {
    return (
      <ul className="nav navbar-nav navbar-right">
        <li className={`dropdown ${menuStatus === menuType ? 'open' : ''}`}>
          <a aria-expanded="false"
            aria-haspopup="true"
            className="dropdown-toggle"
            data-toggle="dropdown"
            href="/#"
            role="button"
            onClick={(event) => setMenuBarStatus(event, menuType)}>
            {label}
            <span className="caret"></span>
          </a>
          {dropdownContent}
        </li>
      </ul>
    );
  }

  function getUserDropdownContent() {
    return (
      <ul className="dropdown-menu">
        <li>
          <a href='/' onClick={onLogoutClick}>Logout</a >
        </li>
      </ul>
    );
  }

  function getDropdownMenu() {
    return (
      <ul className="dropdown-menu">
        <li className={getActiveTabCss('fonts')}>
          <a href={Routes.font.index} target={'_self'}>Fonts</a >
        </li>
        <li className={getActiveTabCss('stickers')}>
          <a href={Routes.sticker.index} target={'_self'}>Stickers</a >
        </li>
        <li className={getActiveTabCss('backgrounds')}>
          <a href={Routes.background.index}>Backgrounds</a >
        </li>
        <li className={getActiveTabCss('products')}>
          <a href={Routes.product.index}>Product management</a >
        </li>
        <li className={getActiveTabCss('layouts')}>
          <a href={Routes.layout.index}>Layout templates</a >
        </li>
        <li className={getActiveTabCss('masterdata')}>
          <a href={Routes.masterdata.index}>Masterdata</a >
        </li>
        <li className={getActiveTabCss('editoroptions')}>
          <a href={Routes.editorOption.index}>Options & Settings</a >
        </li>
      </ul>
    );
  }

  function getSideMainMenu() {
    return (
      <div className="col-sm-3 col-md-2 sidebar">
        <h3>Media</h3>
        <ul className="nav nav-sidebar">
          <li className={getActiveTabCss('fonts')}>
            <a href={Routes.font.index} target={'_self'}>Fonts</a>
          </li>
          <li className={getActiveTabCss('stickers')}>
            <a href={Routes.sticker.index} target={'_self'}>Stickers</a>
          </li>
          <li className={getActiveTabCss('backgrounds')}>
            <a href={Routes.background.index} target={'_self'}>Backgrounds</a>
          </li >
        </ul >
        <h3>Products</h3>
        <ul className="nav nav-sidebar">
          <li className={getActiveTabCss('products')}>
            <a href={Routes.product.index} target={'_self'}>Product management</a>
          </li >
          <li className={getActiveTabCss('layouts')}>
            <a href={Routes.layout.index} target={'_self'}>Layout templates</a >
          </li >
          <li className={getActiveTabCss('masterdata')}>
            <a href={Routes.masterdata.index} target={'_self'}>Masterdata</a >
          </li >
        </ul >
        <h3>Editor Options</h3>
        <ul className="nav nav-sidebar">
          <li className={getActiveTabCss('editoroptions')}>
            <a href={Routes.editorOption.index}>Options & Settings</a>
          </li >
        </ul >
      </div >
    );
  };

  function onLogoutClick(event) {
    event.preventDefault();
    MsalSingleton.getMsalInstance().logoutPopup(MsalSingleton.getMsalInstance().getActiveAccount());
  }
};

export default PageHolder;
