import { Redirect } from 'react-router-dom';

export const redirectToRoute = (route) => {
  return (
    <Redirect to={route} />
  );
};

export const Routes = {
  font: {
    index: '/fonts',
    create: '/fonts/create',
    edit: '/fonts/edit',
  },
  sticker: {
    index: '/stickers',
    create: '/stickers/create',
    edit: '/stickers/edit',
  },
  background: {
    index: '/backgrounds',
    create: '/backgrounds/create',
    edit: '/backgrounds/edit',
  },
  product: {
    index: '/products',
    edit: '/products/edit',
    create: '/products/create',
  },
  layout: {
    index: '/layouts',
    edit: '/layouts/edit',
    create: '/layouts/create',
  },
  masterdata: {
    index: '/masterdata',
  },
  editorOption: {
    index: '/editorOptions',
    edit: '/editorOptions/edit',
    create: '/editorOptions/create'
  }
};
