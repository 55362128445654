import { DELETE, GET, POST, PUT } from './ApiService';
import appConfig from '../config/app-config';
import { getEditorOptionsMock } from './mocks/EditorOptionsServiceMock';

const baseUrl = `${appConfig.baseUri}/management/v1/Options`;
export default class EditorOptionsService {

  static async getAll() {
    if (appConfig.isTest) {
      return {data: getEditorOptionsMock(), error: false };
    }

    return GET({
      url: baseUrl,
      token: true,
    });
  }

  static async updateOrder(id, order) {
    return POST({
      url: `${baseUrl}/UpdateOrder`,
      token: true,
      body: { id, order },
    });
  }

  static async getById(id) {
    return GET({
      url: `${baseUrl}/${id}`,
      token: true,
    });
  }

  static async save(request) {
    return POST({
      url: baseUrl,
      token: true,
      body: request,
    });
  }

  static async updateValue(optionType, request) {
    return PUT({
      url: `${baseUrl}/${optionType}`,
      token: true,
      body: request,
    });
  }

  static async deleteByOptionType(optionType) {
    return DELETE({
      url: `${baseUrl}/${optionType}`,
      token: true,
    });
  }

  static async deleteAllById(ids) {
    return POST({
      url: `${this.baseUrl}/BatchDelete`,
      token: true,
      body: ids,
    });
  }
}
