import { useEffect, useState } from 'react';
import Spinner from '../../components/common/Spinner';
import { Routes } from '../../components/common/UrlUtils';
import SortableTable from '../../components/SortableTable';
import ServerError from '../../components/states/ServerError';
import StickerService from '../../services/StickerService';
import CategoryService from '../../services/CategoryService';
import { ConfirmationDialog } from '../../components/common/ConfirmationDialog';
import { PageUtils } from '../PageUtils';
import { AlertMessage } from '../../utils/AlertMessage';
import { getPreviewImage } from '../../utils/dataUtils';

const StickersPage = () => {
  const EDIT_ROUTE = Routes.sticker.edit;
  const FIELD_ID = 'StickerId';
  const FIELD_SINGULAR = 'sticker';
  const FIELD_PLURAL = 'stickers';
  const HEADERS = [
    { key: 'ImageUrl', value: 'Thumb', sortable: false },
    { key: 'Title', value: 'Title', sortable: true },
    { key: 'Size', value: 'Size', sortable: false },
    { key: 'Categories', value: 'Categories', sortable: true },
    { key: 'LastModified', value: 'Last modified', sortable: true },
    { key: 'Active', value: 'Active', sortable: true },
    { key: 'no_key', value: 'Options', sortable: false }
  ];
  const SEARCH_FIELDS = ['Title', 'Size', 'Categories', 'LastModified', 'Active'];

  const [searchTerm, setSearchTerm] = useState('');
  const [sort, setSort] = useState({ by: 'StickerId', order: 'asc' });
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isServerError, setIsServerError] = useState(false);
  // const [categories, setCategories] = useState([]);
  const [alertMessage, setAlertMessage] = useState({
    message: '',
    type: '',
  });

  useEffect(() => {
    if (isLoading) {
      fetchData();
      setIsLoading(false);
    }

    async function fetchData() {
      const [stickers, categories] = await Promise.all([
        StickerService.getAll(),
        CategoryService.getAll(),
      ]);

      const categoryMap = {};
      if (categories.error) {
        setIsServerError(true);
      } else {
        categories.data.forEach(category => categoryMap[category.CategoryId] = category.Name);
      }
      // setCategories(categories.data.map(c => { return { CategoryId: c.CategoryId, Name: c.Name }; }));

      if (stickers.error) {
        setIsServerError(true);
      } else {

        setItems(stickers.data.map(item => {
          const categoryNames = [];
          item.Categories.forEach(categoryId => categoryNames.push(categoryMap[categoryId]));
          return {
            ...item, Categories: categoryNames, ui: { checked: false }
          };
        }));
      }
    }

  }, [items, isLoading, isServerError, alertMessage]);

  return (
    <>
      {getPageHeaderContent()}
      {AlertMessage.getContent(alertMessage)}
      {getPageContent()}
    </>
  );

  function getPageContent() {
    if (isLoading) {
      return (<Spinner />);
    }

    if (isServerError) {
      return (<ServerError />);
    }

    return (
      <>
        <div className='table-responsive'>
          <SortableTable
            key={'sortable_table'}
            tableHeader={getHeaderContent()}
            tableFooter={getFooterContent()}
            items={getTableItems()}
            onSearchChanged={onSearchHandler}
            onOrderChange={onOrderChangedHandler}
          />
        </div>
      </>
    );
  }
  // @Override
  function onSearchHandler(searchTerm) {
    setSearchTerm(searchTerm.trim().toLowerCase());
  }

  // @Override
  async function onOrderChangedHandler(initIndex, newIndex, id) {
    const result = await StickerService.updateOrder(id, newIndex);
    const sticker = items.find(item => item[FIELD_ID].toString() === id.toString());
    if (result.error) {
      setAlertMessage(AlertMessage.getReorderItemErrorMessage(FIELD_SINGULAR, sticker.Title, newIndex, result));
      return;
    }

    setAlertMessage(AlertMessage.getReorderItemSuccessMessage(FIELD_SINGULAR, sticker.Title, newIndex));
  }

  // @Override
  function getPageHeaderContent() {
    return PageUtils.getPageHeader(FIELD_PLURAL, Routes.sticker.create, FIELD_SINGULAR);
  }

  // @Override
  function getHeaderContent() {
    return PageUtils.getSortableHeaderContent(HEADERS, sort, isAllChecked(), checkAllHandler, changeSort);
  }

  // @Override
  function changeSort(event) {
    event.preventDefault();
    const sortBy = event.target.getAttribute('sort-by');
    const order = sortBy === sort.by ? (sort.order === 'asc' ? 'desc' : 'asc') : 'asc';
    setSort({
      by: sortBy,
      order,
    });
  }

  // @Override
  function getFooterContent() {
    return PageUtils.getSortableFooterContent(HEADERS, isAllChecked(), sort, items, FIELD_PLURAL, checkAllHandler, changeSort, onMultiDeleteHandler);
  }

  // @Override
  function isAllChecked() {
    return PageUtils.isAllChecked(items, SEARCH_FIELDS, searchTerm, sort);
  }

  // @Override
  function getTableItems() {
    return PageUtils.getSortedMatchedItems(items, SEARCH_FIELDS, searchTerm, sort)
      .map(item => {
        return {
          tableData: getItemContent(item),
          active: item.Active,
          key: item[FIELD_ID],
        };
      });
  }

  function getItemContent(item) {
    return (
      <>
        <td key={`${item[FIELD_ID]}_check`}>
          <input
            type='checkbox'
            checked={item.ui.checked}
            onChange={event => onItemSelectChange(item, event)}
          />
        </td>
        <td key={`${item[FIELD_ID]}_thumb`}>
          {/* <span 
                aria-hidden='true' 
                id='@($'noImage{item.StickerId}')' 
                style='display:none' 
                className='glyphicon glyphicon-ban-circle thumb-notfound'>
              </span>
              <img 
                className='img-thumbnail thumb' 
                src='@item.ImageUrl' 
                onerror='this.onerror = null; this.style.display = 'none'; document.getElementById('@($'noImage{item.StickerId}')').style.display = '' ' /> */}
          <img
            className='img-thumbnail thumb'
            src={getPreviewImage(item.ImageUrl, item.LastModified)}
            onError={onImageLoadError}
            alt='sticker thumbnail' />
        </td>

        <td key={`${item[FIELD_ID]}_title`}>{item.Title}</td>
        <td key={`${item[FIELD_ID]}_size`}>{item.Size}</td>
        <td key={`${item[FIELD_ID]}_categories`}>{item.Categories.join(', ')}</td>
        <td key={`${item[FIELD_ID]}_last_modified`}>{item.LastModified}</td>

        <td key={`${item[FIELD_ID]}_active`}>
          <span
            aria-hidden='true'
            className={`glyphicon glyphicon-${item.Active ? 'ok' : 'remove'}`}>
          </span>
          {item.Active ? ' Yes' : ' No'} {item.OriginalActiveState === 2 ? ' (limited)' : ''}
        </td>
        <td key={`${item[FIELD_ID]}_actions`}>
          <a className='btn btn-default btn-xs' href={`${EDIT_ROUTE}/${item[FIELD_ID]}`}>
            <span aria-hidden='true' className='glyphicon glyphicon-pencil'></span> edit
          </a>
          <a className='btn btn-default btn-xs'
            href='/#'
            onClick={event => onDeleteItemHandler(item, event)}>
            <span aria-hidden='true' className='glyphicon glyphicon-trash'></span> delete</a>
        </td>
      </>
    );
  }

  // @Override
  function onImageLoadError(event) {
    // TODO: onImageLoadError
  }

  // @Override
  function checkAllHandler(event) {
    setItems(PageUtils.checkAllSortedMatchedItems(items, SEARCH_FIELDS, searchTerm, sort, event.target.checked));
  }

  // @Override
  async function onDeleteItemHandler(deletedItem, event) {
    event.preventDefault();

    if (!ConfirmationDialog.openDeleteItemConfirmationMessage(FIELD_SINGULAR, deletedItem.Title)) {
      return;
    }

    const result = await StickerService.deleteById(deletedItem[FIELD_ID]);
    if (result.error) {
      setAlertMessage(AlertMessage.getDeleteItemErrorMessage(FIELD_SINGULAR, deletedItem.Title, result));
      return;
    }

    setItems(items.filter(item => item[FIELD_ID] !== deletedItem[FIELD_ID]));
    setAlertMessage(AlertMessage.getDeleteItemSuccessMessage(FIELD_SINGULAR, deletedItem.Title));
  }

  // @Override
  async function onMultiDeleteHandler(event) {
    event.preventDefault();

    const deletedItems = items.filter(item => item.ui.checked).map(item => item[FIELD_ID]);
    if (!ConfirmationDialog.openDeleteItemsConfirmationMessage(deletedItems.length, FIELD_SINGULAR, FIELD_PLURAL)) {
      return;
    }

    const result = await StickerService.deleteAllById({ StickerIds: deletedItems });
    if (result.error) {
      setAlertMessage(AlertMessage.getDeleteItemsErrorMessage(deletedItems.length, FIELD_SINGULAR, FIELD_PLURAL, result));
      return;
    }
    setAlertMessage(AlertMessage.getDeleteItemsSuccessMessage(deletedItems.length, FIELD_SINGULAR, FIELD_PLURAL));
    setItems(items.filter(item => !item.ui.checked));
  }

  // @Override
  function onItemSelectChange(item, event) {
    setItems(PageUtils.toggleSelectedItem(items, FIELD_ID, item[FIELD_ID]));
  }
};

export default StickersPage;
