import { ACTIVE_STATES } from './Enums';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { convertDateTime } from '../../utils/dataUtils';

export const ActiveDatePicker = ({ activeState, activeStartDate, activeEndDate, onActiveStateChange, onDateChange }) => {

  function onActiveStateChangeHandler(value) {
    onActiveStateChange(value);
  }

  function onDateChangeHandler(value, field) {
    onDateChange(value, field);
  }

  return (
    <>
      <div className="form-group">
        <label className="col-md-2 control-label">Active</label>
        <div className="col-sm-10">
          <div className="btn-group" data-toggle="buttons">
            {
              Object.keys(ACTIVE_STATES).map(key => {
                const isActiveCss = activeState === ACTIVE_STATES[key] ? 'active' : '';
                return (
                  <label
                    key={key}
                    className={`btn btn-default ${isActiveCss}`}
                    data-no-z-index={true}
                    defaultValue={ACTIVE_STATES[key]}
                    onClick={() => onActiveStateChangeHandler(ACTIVE_STATES[key])}>
                    {key}
                  </label>
                );
              })
            }
          </div>
        </div>
      </div>

      {activeState === ACTIVE_STATES.Limited ? getActiveRangeButtonsContent() : null}
    </>
  );

  function getActiveRangeButtonsContent() {
    return (
      <div className="form-group activedate">
        <div className="col-sm-offset-2 col-sm-2">
          <label>From</label>
          <DatePicker className='form-control'
            type="text"
            required={true}
            data-val-name="Active From"
            isClearable={true}
            clearButtonTitle={'Clear'}
            todayButton='Today'
            shouldCloseOnSelect={true}
            selected={getDate(activeStartDate)}
            dateFormat={'dd/MM/yyyy'}
            onChange={(value) => onDateChangeHandler(convertDateTime(value), 'ActiveStartDate')} />
        </div>
        <div className="col-sm-2">
          <label>To</label>
          <DatePicker className='form-control'
            required={true}
            data-val-name="Active To"
            isClearable={true}
            clearButtonTitle={'Clear'}
            todayButton='Today'
            shouldCloseOnSelect={true}
            selected={getDate(activeEndDate)}
            dateFormat={'dd/MM/yyyy'}
            onChange={(value) => onDateChangeHandler(convertDateTime(value), 'ActiveEndDate')} />
        </div>
      </div>
    );

    function getDate(dateString) {
      if (!dateString) {
        return null;
      }

      return new Date(dateString);
    }
  }
};
