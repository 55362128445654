import { GET, POST, PUT, DELETE } from './ApiService';
import appConfig from '../config/app-config';
import { getAllProductsMock, getSpecificProductMock } from './mocks/ProductServiceMock';

const baseUrl = `${appConfig.baseUri}/management/v1/Products`;
export default class ProductService {

  static async getAll() {
    if (appConfig.isTest) {
      return { data: getAllProductsMock(), error: false };
    }

    return GET({
      url: baseUrl,
      token: true,
    });
  }

  static async updateOrder(id, order) {
    return POST({
      url: `${baseUrl}/UpdateOrder`,
      token: true,
      body: { id, order },
    });
  }

  static async getById(id) {
    if (appConfig.isTest) {
      return { data: getSpecificProductMock(), error: false };
    }

    return GET({
      url: `${baseUrl}/${id}`,
      token: true,
    });
  }

  static async save(request) {
    return POST({
      url: baseUrl,
      token: true,
      body: request,
    });
  }

  static async update(id, request) {
    return PUT({
      url: `${baseUrl}/${id}`,
      token: true,
      body: request,
    });
  }

  static async deleteById(id) {
    return DELETE({
      url: `${baseUrl}/${id}`,
      token: true,
    });
  }

  static async deleteAllById(ids) {
    return POST({
      url: `${baseUrl}/BatchDelete`,
      token: true,
      body: ids,
    });
  }
}
