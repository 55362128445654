const appConfig = {
  region: process.env.REACT_APP_REGION,
  userPool: process.env.REACT_APP_COGNITO_USERPOOL_ID,
  userPoolDomain: process.env.REACT_APP_COGNITO_USERPOOL_DOMAIN,
  clientId: process.env.REACT_APP_CLIENT_ID,
  callbackUri: process.env.REACT_APP_CALLBACK_URI,
  signoutUri: process.env.REACT_APP_SIGNOUT_URI,
  tokenScopes: process.env.REACT_APP_TOKEN_SCOPES.split(','),
  editorUri: process.env.REACT_APP_EDITOR_URL,
  isTest: process.env.REACT_APP_IS_TEST === 'true',
  apiServer: process.env.REACT_APP_API_SERVER || '',
  hema: {
    clientId: process.env.REACT_APP_HEMA_CLIENT_ID,
    clientSecret: process.env.REACT_APP_HEMA_CLIENT_SECRET,
    securityEndpoint: process.env.REACT_APP_HEMA_SECURITY_ENDPOINT,
  },
  msal: {
    clientId: process.env.REACT_APP_MSAL_CLIENT_ID,
    redirectUrl: process.env.REACT_APP_MSAL_REDIECT_URL,
  },
  // baseUri: `${process.env.REACT_APP_BACKEND_APPLICATION_URL}/customeditor/api/editor`,
  baseUri: '/customeditor/api/editor',
};

export default appConfig;
