export const ValidationErrorMessage = ({ errorMessage }) => {

  return (getValidationErrorContent());

  function getValidationErrorContent() {
    if (!errorMessage) {
      return null;
    }

    return (<span className="field-validation-valid text-danger">{errorMessage}</span>);
  };
};
