import { useEffect, useState } from 'react';
import Paging from './common/Paging';

const PAGE_SIZE = [
  {
    label: 'All',
    value: -1,
    isDefault: false,
  },
  {
    label: '100',
    value: 100,
    isDefault: true,
  },
  {
    label: '50',
    value: 50,
    isDefault: false,
  },
  {
    label: '25',
    value: 25,
    isDefault: false,
  },
];
// {
//   ONE_HUNDRED: 100,
//   FIFTY: 50,
//   TWENTY_FIVE: 25,
//   ALL: -1,
// };

const SortableTable = ({ tableHeader, tableFooter, items, onSearchChanged, onOrderChange }) => {
  let dragItem = null;
  let dragItemIndex = -1;
  const [search] = useState('');
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE.find(pageSize => pageSize.isDefault).value);

  useEffect(() => {
  }, [pageIndex, pageSize]);

  return (
    <>
      {getTableHeaderContent()}
      <table
        className="table table-striped table-hover display dataTable"
        id="sortable-table">
        {tableHeader}
        {tableFooter}
        {getTableContent()}
      </table>

      <Paging
        perPage={pageSize}
        total={items.length}
        activePage={pageIndex}
        onPageChanged={onPageChangedHandler} />
    </>
  );

  function onPageChangedHandler(pageIndex) {
    setPageIndex(pageIndex);
  }

  function getTableContent() {
    return (
      <tbody id="sortable" draggable={true}>
        {getTableData()}
      </tbody>
    );
  };

  function getTableData() {
    const tableData = items
      .slice((pageIndex - 1) * pageSize, pageIndex * pageSize)
      .map((item, index) => {
        return (
          <tr
            className={`ui-state-default ${item.active ? '' : 'danger'}`}
            draggable={true}
            data-sortable={true}
            key={index}
            data-key={item.key}
            onDragStart={onDragStartHandler}
            onDragOver={onDragOverHandler}
            onDragEnd={onDragEndHandler}
          >
            {item.tableData}
          </tr>
        );
      });

    if (tableData.length) {
      return tableData;
    }

    return (
      <tr className="odd">
        <td valign="top" colSpan="100" className="dataTables_empty">No data available in table</td>
      </tr>
    );
  }

  function onDragStartHandler(event) {
    const element = event.target.closest('tr');
    if (!element || !element.hasAttribute('data-sortable')) {
      return;
    }

    dragItem = element;

    dragItemIndex = findIndexForKey(dragItem.dataset.key);

    event.dataTransfer.effectAllowed = 'move';
    event.dataTransfer.dropEffect = 'move';

    dragItem.setAttribute('placeholder', 'placeholder');

    // Firefox requires calling dataTransfer.setData
    // for the drag to properly work
    event.dataTransfer.setData('text/html', event.target);
  }

  function onDragEndHandler(event) {
    if (!dragItem) {
      return;
    }

    dragItem.removeAttribute('placeholder');
    const index = findIndexForKey(dragItem.dataset.key);

    if (onOrderChange) {
      onOrderChange(dragItemIndex, index, dragItem.getAttribute('data-key'));
    }

    dragItem = null;
    dragItemIndex = -1;
  }

  // Key must be passed as string since it's DOM value
  function findIndexForKey(key) {
    const table = document.getElementById('sortable');
    const index = Array.from(table.childNodes).findIndex((node) => node.dataset.key === key);
    return index;
  }

  function onDragOverHandler(event) {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
    const tr = event.target.closest('tr');
    if (!tr || tr.hasAttribute('placeholder') || !tr.hasAttribute('data-sortable')) {
      return;
    }

    const parent = document.getElementById('sortable');
    const children = Array.from(parent.childNodes);
    if (children.indexOf(dragItem) > children.indexOf(tr)) {
      tr.before(dragItem);
    } else {
      tr.after(dragItem);
    }
  }

  function getTableHeaderContent() {
    return (
      <>
        <div className="w100 row font-header">
          <div className="col-sm-6">
            <label>Show
              <select
                className="form-control input-sm"
                onChange={onPagingSizeChange}
                defaultValue={pageSize}>
                {getPageSizeDropdownContent()}
              </select> entries</label>
          </div>
          <div className="col-sm-6 right">
            <label>Search:
              <input
                type="search"
                className="form-control input-sm"
                defaultValue={search}
                onChange={onSearchHandler} />
            </label>
          </div>
        </div>
      </>
    );
  }

  function getPageSizeDropdownContent() {
    return PAGE_SIZE.map(pageSize => {
      return (<option key={pageSize.value} value={pageSize.value} checked={pageSize.isDefault}>{pageSize.label}</option>);
    });
  }

  function onPagingSizeChange(event) {
    if (+event.target.value === PAGE_SIZE.ALL) {
      setPageSize(items.length);
      return;
    }

    setPageSize(+event.target.value);
  }

  function onSearchHandler(event) {
    onSearchChanged(event.target.value.trim());
  }

};

export default SortableTable;
