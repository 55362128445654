import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Spinner from '../../components/common/Spinner';
import { Routes } from '../../components/common/UrlUtils';
import ServerError from '../../components/states/ServerError';
import { validateFormWithCustomMessages } from '../../utils/validation';
import { appendIfExists } from '../../utils/dataUtils';
import FilterService from '../../services/FilterService';
import { AlertMessage } from '../../utils/AlertMessage';

export const FilterUpsertPage = () => {
  const FIELD_SINGULAR = 'filter';
  const history = useHistory();
  const { filterId } = useParams();
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [serverError, setServerError] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    message: '',
    type: '',
  });
  const [item, setItem] = useState({
    InputFile: {
      value: '',
    },
    FilterId: {
      value: '',
      errorMessage: '',
    },
    HtmlClass: {
      value: '',
      errorMessage: '',
    },
    LastModified: {
      value: '',
    },
    LastModifiedBy: {
      value: '',
    },
    ImageUrl: {
      value: '',
    }
  });

  useEffect(() => {
    if (isLoading) {
      fetchData();
      setIsLoading(false);
    }

    async function fetchData() {
      if (isEdit()) {
        const filterResponse = await FilterService.getById(filterId);
        if (filterResponse.error) {
          setServerError(true);
        } else {
          const copy = { ...item };
          Object.keys(item).forEach(key => copy[key].value = filterResponse.data[key]);
          copy.ImageUrl.value += `?${copy.LastModified.value}`;
          setItem(copy);
        }
      }
    }

  }, [item, isLoading, isFormDisabled, serverError, alertMessage]);

  return (
    <>
      <h2 className='sub-header'>Filter - {isEdit() ? 'Edit' : 'Add'} <a className="btn btn-default btn-xs" href={Routes.editorOption.index}>
        <span aria-hidden="true" className="glyphicon glyphicon-arrow-left"></span> back</a>
      </h2>
      {AlertMessage.getContent(alertMessage)}
      {getFormContent()}
    </>
  );

  function isEdit() {
    return filterId !== undefined;
  }

  function getFormContent() {
    if (isLoading) {
      return (<Spinner />);
    }

    if (serverError) {
      return (<ServerError />);
    }

    return (
      <form class="form-horizontal" onSubmit={onSubmitHandler}>
        <fieldset disabled={isFormDisabled}>
          {getLastModifiedContent()}
          {getUploadFileContent()}
          <div class="form-group">
            <label class="col-sm-2 control-label" for="FilterId">Filter Id</label>
            <div class="col-sm-5">
              <input
                type="text"
                class="form-control"
                placeholder="Filter ID"
                name="FilterId"
                required={true}
                defaultValue={item.FilterId.value}
                onInput={onInputChangeHandler}
                onBlur={onInputChangeHandler}
                onInvalid={onInvalidHandler}
              />
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-2 control-label" for="HtmlClass">Html Class</label>
            <div class="col-sm-5">
              <input
                type="text"
                class="form-control"
                placeholder="Html Class"
                name="HtmlClass"
                required={true}
                defaultValue={item.HtmlClass.value}
                onInput={onInputChangeHandler}
                onBlur={onInputChangeHandler}
                onInvalid={onInvalidHandler}
              />
            </div>
          </div>
          <div class="form-group">
            <div class="col-sm-offset-2 col-sm-10">
              <button class="btn btn-primary" type="submit">Save</button>
            </div>
          </div>
        </fieldset>
      </form>
    );
  }

  function getLastModifiedContent() {
    if (!isEdit()) {
      return null;
    }

    return (
      <div class="form-group">
        <label class="col-sm-2 control-label"><strong>Last modified</strong></label>
        <div class="col-sm-10">
          <p class="form-control-static">{item.LastModified.value} by {item.LastModifiedBy.value}</p>
        </div>
      </div>
    );
  }

  function getUploadFileContent() {
    if (isEdit()) {
      return (
        <div class="form-group">
          <div class="col-sm-2 text-right">
            <img
              class="img-responsive thumb thumb-hover"
              src={item.ImageUrl.value} />
          </div>
          <div class="col-sm-10">
            <input
              name="InputFile"
              preview="ImageUrl"
              type="file"
              accept="image/*"
              onInput={onInputChangeHandler}
              onBlur={onInputChangeHandler}
              onInvalid={onInvalidHandler}
            />
          </div>
        </div>
      );
    }

    return (
      <div class="form-group">
        <label class="col-sm-2 control-label" for="InputFile">Upload</label>
        <div class="col-sm-10">
          <input
            required={true}
            name="InputFile"
            type="file"
            accept="image/*"
            onInput={onInputChangeHandler}
            onBlur={onInputChangeHandler}
            onInvalid={onInvalidHandler}
          />
        </div>
      </div>
    );
  }

  // Event Handlers
  function onInputChangeHandler(event) {
    const { result, valid } = validateFormWithCustomMessages(item, event);
    setIsFormValid(valid);

    if (event.target.type === 'file' && event.target.files && event.target.files.length) {
      const preview = event.target.getAttribute('preview');
      if (preview) {
        // Reset last modified because of the preview image
        result[preview].value = URL.createObjectURL(event.target.files[0]);
      }
    }

    setItem(result);
  }

  function onInvalidHandler(event) {
    // event.preventDefault();
  }

  async function onSubmitHandler(event) {
    event.preventDefault();

    if (!isFormValid) {
      setAlertMessage(AlertMessage.getInvalidFormMessage());
      return;
    }

    const formData = new FormData();
    appendIfExists('InputFile', item.InputFile.value, formData);
    appendIfExists('FilterId', item.FilterId.value, formData);
    appendIfExists('HtmlClass', item.HtmlClass.value, formData);

    if (isEdit()) {
      updateFilter(filterId, formData);
    } else {
      saveFilter(formData);
    }
  }

  async function updateFilter(filterId, formData) {
    setIsFormDisabled(true);
    const result = await FilterService.update(filterId, formData);
    setIsFormDisabled(false);
    if (result.error) {
      setAlertMessage(AlertMessage.getSaveItemErrorMessage(FIELD_SINGULAR, item.FilterId.value, result));
      return;
    }

    setAlertMessage(AlertMessage.getSaveItemSuccessMessage(FIELD_SINGULAR, item.FilterId.value));
    setTimeout(() => history.push(Routes.editorOption.index), 1000);
  }

  async function saveFilter(formData) {
    setIsFormDisabled(true);
    const result = await FilterService.save(formData);
    setIsFormDisabled(false);
    if (result.error) {
      setAlertMessage(AlertMessage.getSaveItemErrorMessage(FIELD_SINGULAR, item.FilterId.value, result));
      return;
    }

    setAlertMessage(AlertMessage.getSaveItemSuccessMessage(FIELD_SINGULAR, item.FilterId.value));
    setTimeout(() => history.push(Routes.editorOption.index), 1000);
  }
};
