import styles from '../../styles/components/states/ServerError.module.scss';
import alertImage from './../../../src/assets/alert.svg';

const ServerError = () => {
  return (
    <div className={styles.container}>
      <img className={styles.icon} src={alertImage} alt="" />
      <div className={styles.messageWrapper}>
        <div className={styles.message}>
          <p>Server Error</p>
        </div>
      </div>
    </div>
  );
};

export default ServerError;
