const validationTypes = {
  // regex that validates a part of a name: a valid part consists of 1 or more international letters,
  // possibly with a space, single quote or dash in between.
  NAME_PART: /^(([\p{L}'][ \p{L}'-]*[\p{L}])|([\p{L}]))$/u,
  EMAIL: /\S+@\S+.\S+/,
  PHONE: /^[+-]?\d+(?:[.,]\d*)?$/,
};

export const validation = (value, type) => {
  if (validationTypes[type]) {
    return validationTypes[type].test(value);
  }

  return false;
};

const DEFAULT_MESSAGES = {
  required: (field) => `The ${field} field is required.`,
  min: (value) => `Value must be greater than or equal to ${value}.`,
  max: (value) => `Value must be less than or equal to ${value}.`,
};

/**
 * 
 * @param item - Format { [key]: { value: T, errorMessage: string } }
 * @param event - InputEvent
 * @returns - { result: Copy<T>, valid: boolean }
 * 
 * Event.target.validity fields:
 * badInput
 * customError
 * patternMismatch
 * rangeOverflow    - Implemented
 * rangeUnderflow   - Implemented
 * stepMismatch
 * tooLong
 * tooShort
 * typeMismatch
 * valid
 * valueMissing     - Implemented
 */
export function validateFormWithCustomMessages(item, event) {
  const requiredMessage = event.target.getAttribute('data-val-required');
  const minMessage = event.target.getAttribute('data-val-min');
  const maxMessage = event.target.getAttribute('data-val-max');
  const validationName = event.target.getAttribute('data-val-name');
  const minValue = event.target.getAttribute('min');
  const maxValue = event.target.getAttribute('max');
  const inputType = event.target.getAttribute('type');
  const inputRquired = event.target.getAttribute('required');
  const isMultiSelect = event.target.hasAttribute('multiple');
  const fieldName = event.target.getAttribute('name');
  const nodeName = event.target.nodeName;

  const validity = event.target.validity;
  const copy = { ...item };
  if (!validity.valid) {

    let hasErrorMessage = false;

    if (validity.valueMissing) {
      copy[fieldName].errorMessage = requiredMessage || DEFAULT_MESSAGES.required(validationName);
      hasErrorMessage = true;
    }

    if (validity.rangeOverflow && !hasErrorMessage) {
      copy[fieldName].errorMessage = minMessage || DEFAULT_MESSAGES.min(minValue);
      hasErrorMessage = true;
    }

    if (validity.rangeUnderflow && !hasErrorMessage) {
      copy[fieldName].errorMessage = maxMessage || DEFAULT_MESSAGES.max(maxValue);
      hasErrorMessage = true;
    }


    return {
      result: copy,
      valid: false,
    };
  }

  copy[fieldName].errorMessage = '';

  let value = event.target.value;
  if (inputType === 'checkbox' || inputType === 'radio') {
    value = event.target.checked;
  }

  if (copy[fieldName] && copy[fieldName].value !== undefined) {
    copy[fieldName].value = value;
  }


  if (nodeName === 'SELECT') {
    const selectedValues = [];
    const selectedOptions = new Array(event.target.selectedOptions);
    for (let option of selectedOptions[0]) {
      selectedValues.push(option.value);
    }

    if (selectedValues.length === 0 && inputRquired) {
      copy[fieldName].errorMessage = requiredMessage;
      return {
        result: copy,
        valid: false,
      };
    }

    if (isMultiSelect) {
      copy[fieldName].values = selectedValues;
    } else {
      copy[fieldName].value = selectedValues[0] || '';
    }
  }

  if (inputType === 'file') {
    if (isMultiSelect) {
      copy[fieldName].values = event.target.files;
    } else {
      copy[fieldName].value = event.target.files[0];
    }
  }
  return {
    result: copy,
    valid: true,
  };
}
