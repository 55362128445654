import { MsalSingleton } from '..';
import { getCognitoSession, parseCognitoWebResponse, getCognitoSignInUri, refreshCognitoSession } from './cognitoUtils';
import { localeStorageObjectKeys, REFRESH_TOKEN_THRESHOLD_IN_SECONDS, SET_SESSION_EVENT_NAME } from './constants';

export const hasAuthorizationCodeInUrl = (location) => {
  return (location.hash && location.hash.indexOf('code=') >= 0) || (location.search && location.search.indexOf('code=') >= 0);
};

export const isTokenExpired = (threshold = 0) => {
  const expirationTime = getTokenExpirationTime();
  const currentTime = Math.round(new Date().getTime() / 1000);
  return expirationTime - currentTime < threshold;
};

export const initSessionFromCallbackURI = async (href) => {
  setLoggedIn();
  await setSession(href);
};

const setLoggedIn = () => {
  saveToLocalStorage(localeStorageObjectKeys.LOGGED_IN, 'true');
};

const setSession = async (href) => {
  await parseCognitoWebResponse(href);
  const session = await getCognitoSession();
  saveToLocalStorage(localeStorageObjectKeys.SESSION, session);
  triggerSetSessionEvent(session);
  return session;
};

export const setSSORedirectDataAndLogout = (path, querystring, hash) => {
  saveToLocalStorage(localeStorageObjectKeys.SSO_REDIRECT_DATA, { path, querystring, hash });
  localStorage.removeItem(localeStorageObjectKeys.LOGGED_IN);
  window.open(getCognitoSignInUri(), '_self');
};

const getFromLocalStorage = (key) => {
  return localStorage.getItem(key);
};

const saveToLocalStorage = (key, value) => {
  localStorage.setItem(key, typeof value === 'object' ? JSON.stringify(value) : value);
};

export const getSSORedirectDataFromLocaleStorage = () => {
  return JSON.parse(getFromLocalStorage(localeStorageObjectKeys.SSO_REDIRECT_DATA));
};

export function getSession() {
  return JSON.parse(getFromLocalStorage(localeStorageObjectKeys.SESSION));
}

export function getTokenExpirationTime() {
  const session = getSession();
  if (session?.credentials?.expires) {
    return session.credentials.expires;
  }
  return -1;
}

export const getToken = async () => {
  const session = getSession();
  if (!isTokenExpired(REFRESH_TOKEN_THRESHOLD_IN_SECONDS) || !session.credentials.refreshToken) {
    return session.credentials.idToken;
  }
  try {
    const refreshedSession = await refreshCognitoSession(session.credentials.refreshToken);
    if (refreshedSession) {
      saveToLocalStorage(localeStorageObjectKeys.SESSION, refreshedSession);
      triggerSetSessionEvent(refreshedSession);
      return refreshedSession.credentials.idToken;
    }
  } catch (error) {
    window.open(getCognitoSignInUri(), '_self');
  }
  return session.credentials.idToken;
};

function triggerSetSessionEvent(session) {
  window.dispatchEvent(new CustomEvent(SET_SESSION_EVENT_NAME, { detail: session }));
}

export function isLoggedIn() {
  return localStorage.getItem(localeStorageObjectKeys.LOGGED_IN) === 'true';
}

export function getLoggedUserName() {
  const msal = MsalSingleton.getMsalInstance();
  if (msal && msal.getActiveAccount() && msal.getActiveAccount().name) {
    return msal.getActiveAccount().name;
  }
  const username = localStorage.getItem('custom_editor_user');
  return username || 'Anonymous User';
}
