import { useEffect } from 'react';

const Paging = ({ perPage, total, activePage, onPageChanged }) => {
  const numberOfLinks = 5;
  const numberOfPages = Math.ceil(total / perPage);


  useEffect(() => {
  }, [activePage]);

  function onPreviousPage(event) {
    event.preventDefault();
    if (activePage === 1) {
      return;
    }

    onPageChanged(activePage - 1);
  }

  function onNextPage(event) {
    event.preventDefault();
    if (activePage === numberOfPages) {
      return;
    }
    onPageChanged(activePage + 1);
  }

  function onPageIndex(event) {
    event.preventDefault();
    const dataIndex = event.target.getAttribute('data-index');
    if (!dataIndex) {
      return;
    }


    const index = +dataIndex;
    if (index < 1 || index > numberOfPages) {
      return;
    }
    onPageChanged(index);
  }

  return (
    <div className="flex-row w100 flex-right paging">
      <ul className="pagination">
        <li
          className={`paginate_button ${activePage === 1 ? 'disabled' : ''}`}>
          <a href="/#" tabIndex="0" onClick={onPreviousPage}>Previous</a>
        </li>
        {getTabsContent()}
        <li
          className={`paginate_button ${activePage === numberOfPages || numberOfPages === 0 ? 'disabled' : ''}`}>
          <a href="/#" tabIndex="0" onClick={onNextPage}>Next</a>
        </li>
      </ul>
    </div>
  );

  function getTabsContent() {
    let tabsHtml = [];

    if (numberOfPages <= numberOfLinks) {
      for (let i = 0; i < numberOfPages; i++) {
        tabsHtml.push(getTabContent(i + 1));
      }
      return tabsHtml;
    }

    if (activePage < numberOfLinks) {
      for (let i = 0; i < numberOfLinks; i++) {
        tabsHtml.push(getTabContent(i + 1));
      }
      tabsHtml.push(getTabDotsContent('right_dots'));
      tabsHtml.push(getTabContent(numberOfPages));

      return tabsHtml;
    }

    if (activePage + numberOfLinks - 1 > numberOfPages) {
      tabsHtml.push(getTabContent(1));
      tabsHtml.push(getTabDotsContent('left_dots'));

      const index = Math.min(activePage - 1, numberOfPages - numberOfLinks + 1);
      for (let i = index; i <= numberOfPages; i++) {
        tabsHtml.push(getTabContent(i));
      }
      return tabsHtml;
    }

    tabsHtml.push(getTabContent(1));
    tabsHtml.push(getTabDotsContent('left_dots'));

    tabsHtml.push(getTabContent(activePage - 1));
    tabsHtml.push(getTabContent(activePage));
    tabsHtml.push(getTabContent(activePage + 1));

    tabsHtml.push(getTabDotsContent('right_dots'));
    tabsHtml.push(getTabContent(numberOfPages));

    return tabsHtml;
  }

  function getTabContent(pageIndex) {
    return (
      <li
        key={pageIndex}
        className={`paginate_button ${activePage === pageIndex ? 'active' : ''}`}>
        <a
          href="/#"
          tabIndex="0"
          data-index={pageIndex}
          onClick={onPageIndex}>{pageIndex}</a>
      </li>
    );
  }

  function getTabDotsContent(key) {
    return (
      <li
        key={key}
        className='paginate_button not-allowed'>
        <a
          className='not-allowed'
          href="/#"
          tabIndex="0"
          onClick={onPageIndex}>...</a>
      </li>
    );
  }
};

export default Paging;
