/* eslint-disable */
export function getAllStickers() {
  return [
    {
      'Created': '05/09/2017 07:12:56',
      'LastModified': '06/18/2019 13:41:33',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 9,
      'Title': 'Happy',
      'Size': '{Width=1034, Height=571}',
      'Order': 2,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        25
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-9.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:13:16',
      'LastModified': '06/18/2019 13:41:26',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 10,
      'Title': 'Enjoy',
      'Size': '{Width=1042, Height=187}',
      'Order': 1,
      'Categories': [
        4,
        7
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        22,
        23,
        24,
        25
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-10.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:13:26',
      'LastModified': '10/11/2022 09:58:26',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 11,
      'Title': 'Ster',
      'Size': '{Width=662, Height=630}',
      'Order': 0,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        17,
        22,
        23,
        24,
        25,
        26
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-11.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:13:36',
      'LastModified': '11/20/2018 14:27:12',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 12,
      'Title': 'Lips',
      'Size': '{Width=660, Height=369}',
      'Order': 6,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        22,
        23,
        24
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-12.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:13:55',
      'LastModified': '05/09/2017 07:13:55',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 13,
      'Title': 'Strik',
      'Size': '{Width=625, Height=335}',
      'Order': 3,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-13.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:14:06',
      'LastModified': '05/09/2017 07:14:06',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 14,
      'Title': 'Cirkel',
      'Size': '{Width=623, Height=624}',
      'Order': 4,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-14.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:14:19',
      'LastModified': '05/09/2017 07:14:19',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 15,
      'Title': 'Spreekwolk',
      'Size': '{Width=833, Height=736}',
      'Order': 5,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-15.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:14:27',
      'LastModified': '05/09/2017 07:14:27',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 16,
      'Title': 'Bloem',
      'Size': '{Width=662, Height=737}',
      'Order': 7,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-16.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:14:41',
      'LastModified': '10/11/2022 09:58:40',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 17,
      'Title': 'Oogjes',
      'Size': '{Width=693, Height=332}',
      'Order': 8,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        25,
        26
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-17.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:14:52',
      'LastModified': '11/20/2018 14:27:26',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 18,
      'Title': 'Snor',
      'Size': '{Width=681, Height=199}',
      'Order': 9,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        22,
        23,
        24
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-18.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:15:01',
      'LastModified': '05/09/2017 07:15:01',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 19,
      'Title': 'Bril',
      'Size': '{Width=688, Height=229}',
      'Order': 10,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-19.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:15:37',
      'LastModified': '10/11/2022 09:58:53',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 20,
      'Title': 'Beer',
      'Size': '{Width=540, Height=754}',
      'Order': 11,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        26
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-20.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:15:47',
      'LastModified': '05/09/2017 07:15:47',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 21,
      'Title': 'Baby',
      'Size': '{Width=729, Height=658}',
      'Order': 12,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-21.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:16:04',
      'LastModified': '10/11/2022 09:59:07',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 22,
      'Title': 'Hart roze',
      'Size': '{Width=524, Height=729}',
      'Order': 13,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        17,
        18,
        19,
        21,
        22,
        23,
        24,
        25,
        26
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-22.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:16:14',
      'LastModified': '05/09/2017 07:16:14',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 23,
      'Title': 'Hart blauw',
      'Size': '{Width=524, Height=729}',
      'Order': 14,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-23.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:16:24',
      'LastModified': '05/09/2017 07:16:24',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 24,
      'Title': 'Ster blauw',
      'Size': '{Width=625, Height=570}',
      'Order': 15,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-24.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:16:39',
      'LastModified': '10/11/2022 09:59:22',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 25,
      'Title': 'Hoera een meisje',
      'Size': '{Width=508, Height=732}',
      'Order': 16,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        26
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-25.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:16:56',
      'LastModified': '05/09/2017 07:16:56',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 26,
      'Title': 'Welkom kleintje',
      'Size': '{Width=829, Height=613}',
      'Order': 17,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-26.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:17:13',
      'LastModified': '05/09/2017 07:17:13',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 27,
      'Title': 'Welkom kleintjes',
      'Size': '{Width=829, Height=613}',
      'Order': 18,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-27.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:17:25',
      'LastModified': '05/09/2017 07:17:25',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 28,
      'Title': 'Sinasappel',
      'Size': '{Width=729, Height=729}',
      'Order': 19,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-28.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:17:37',
      'LastModified': '05/09/2017 07:17:37',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 29,
      'Title': 'Blaadje',
      'Size': '{Width=717, Height=238}',
      'Order': 20,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-29.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:17:48',
      'LastModified': '05/09/2017 07:17:48',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 30,
      'Title': 'Bloem',
      'Size': '{Width=359, Height=931}',
      'Order': 21,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-30.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:18:02',
      'LastModified': '05/09/2017 07:18:02',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 31,
      'Title': 'Bloem',
      'Size': '{Width=383, Height=942}',
      'Order': 22,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-31.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:18:14',
      'LastModified': '10/11/2022 09:59:38',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 32,
      'Title': 'Flamingo',
      'Size': '{Width=368, Height=697}',
      'Order': 23,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        26
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-32.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:18:28',
      'LastModified': '05/09/2017 07:18:28',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 33,
      'Title': 'Ananas',
      'Size': '{Width=405, Height=738}',
      'Order': 24,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-33.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:18:46',
      'LastModified': '05/09/2017 07:18:46',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 34,
      'Title': 'Herfst blaadjes',
      'Size': '{Width=255, Height=546}',
      'Order': 25,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-34.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:18:59',
      'LastModified': '05/09/2017 07:18:59',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 35,
      'Title': 'Winter',
      'Size': '{Width=476, Height=785}',
      'Order': 26,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-35.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:19:14',
      'LastModified': '05/09/2017 07:19:14',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 36,
      'Title': 'Astronaut',
      'Size': '{Width=589, Height=988}',
      'Order': 27,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-36.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:19:26',
      'LastModified': '05/09/2017 07:19:26',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 37,
      'Title': 'Jarig',
      'Size': '{Width=1036, Height=1026}',
      'Order': 28,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-37.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:19:38',
      'LastModified': '05/09/2017 07:19:38',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 38,
      'Title': 'Kikker',
      'Size': '{Width=1042, Height=1040}',
      'Order': 29,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-38.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:19:56',
      'LastModified': '05/09/2017 07:19:56',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 39,
      'Title': 'Kroon',
      'Size': '{Width=1042, Height=651}',
      'Order': 30,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-39.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:20:13',
      'LastModified': '05/09/2017 07:20:13',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 40,
      'Title': 'Love',
      'Size': '{Width=988, Height=311}',
      'Order': 31,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-40.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:20:34',
      'LastModified': '05/09/2017 07:20:34',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 41,
      'Title': 'Piratenmuts',
      'Size': '{Width=1042, Height=591}',
      'Order': 32,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-41.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:20:45',
      'LastModified': '05/09/2017 07:20:45',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 42,
      'Title': 'Takkie',
      'Size': '{Width=1042, Height=1042}',
      'Order': 33,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-42.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:20:58',
      'LastModified': '05/09/2017 07:20:58',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 43,
      'Title': 'Ballon geel',
      'Size': '{Width=377, Height=1024}',
      'Order': 34,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-43.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:21:11',
      'LastModified': '05/09/2017 07:21:11',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 44,
      'Title': 'Getrouwd',
      'Size': '{Width=1028, Height=349}',
      'Order': 35,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-44.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:21:44',
      'LastModified': '11/28/2017 14:40:07',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 45,
      'Title': 'Janneke kado',
      'Size': '{Width=1042, Height=1105}',
      'Order': 36,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        18
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-45.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:21:57',
      'LastModified': '05/09/2017 07:21:57',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 46,
      'Title': 'Kroon',
      'Size': '{Width=1042, Height=845}',
      'Order': 37,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-46.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:22:19',
      'LastModified': '05/09/2017 07:22:19',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 47,
      'Title': 'Kroon',
      'Size': '{Width=1042, Height=845}',
      'Order': 38,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-47.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:22:30',
      'LastModified': '05/09/2017 07:22:30',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 48,
      'Title': 'Papagaai',
      'Size': '{Width=1042, Height=928}',
      'Order': 39,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-48.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:22:43',
      'LastModified': '05/09/2017 07:22:43',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 49,
      'Title': 'Robot',
      'Size': '{Width=674, Height=1009}',
      'Order': 40,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-49.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:22:55',
      'LastModified': '05/09/2017 07:22:55',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 50,
      'Title': 'The king',
      'Size': '{Width=1044, Height=197}',
      'Order': 41,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-50.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:23:10',
      'LastModified': '05/09/2017 07:23:10',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 51,
      'Title': 'Ballonnen groen',
      'Size': '{Width=785, Height=1007}',
      'Order': 42,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-51.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:23:27',
      'LastModified': '05/09/2017 07:23:27',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 52,
      'Title': 'Je bent mijn liefste',
      'Size': '{Width=487, Height=1042}',
      'Order': 43,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-52.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:23:37',
      'LastModified': '05/09/2017 07:23:37',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 53,
      'Title': 'Love',
      'Size': '{Width=1038, Height=948}',
      'Order': 44,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-53.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:23:49',
      'LastModified': '05/09/2017 07:23:49',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 54,
      'Title': 'Racket',
      'Size': '{Width=420, Height=1016}',
      'Order': 45,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-54.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:24:01',
      'LastModified': '05/09/2017 07:24:01',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 55,
      'Title': 'Zwaard',
      'Size': '{Width=461, Height=1042}',
      'Order': 46,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-55.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:24:13',
      'LastModified': '05/09/2017 07:24:13',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 56,
      'Title': 'Hoeravlag',
      'Size': '{Width=1045, Height=252}',
      'Order': 47,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-56.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:24:27',
      'LastModified': '05/09/2017 07:24:27',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 57,
      'Title': 'Rood hartje',
      'Size': '{Width=624, Height=1007}',
      'Order': 48,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-57.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:24:48',
      'LastModified': '05/09/2017 07:24:48',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 58,
      'Title': 'Hoera banner',
      'Size': '{Width=1029, Height=530}',
      'Order': 49,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-58.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:25:05',
      'LastModified': '05/09/2017 07:25:05',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 59,
      'Title': 'Raket',
      'Size': '{Width=659, Height=984}',
      'Order': 50,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-59.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:25:18',
      'LastModified': '05/09/2017 07:25:18',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 60,
      'Title': 'Twee hartjes',
      'Size': '{Width=968, Height=754}',
      'Order': 51,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-60.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:25:31',
      'LastModified': '05/09/2017 07:25:31',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 61,
      'Title': 'Hoera',
      'Size': '{Width=995, Height=543}',
      'Order': 52,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-61.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:25:43',
      'LastModified': '05/09/2017 07:25:43',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 62,
      'Title': 'Lijn hartjes',
      'Size': '{Width=982, Height=958}',
      'Order': 53,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-62.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:26:23',
      'LastModified': '05/09/2017 07:26:23',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 63,
      'Title': 'Roze hartje',
      'Size': '{Width=624, Height=1017}',
      'Order': 54,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-63.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:26:32',
      'LastModified': '05/09/2017 07:26:32',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 64,
      'Title': 'Ufo',
      'Size': '{Width=914, Height=958}',
      'Order': 55,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-64.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:26:42',
      'LastModified': '05/09/2017 07:26:42',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 65,
      'Title': 'Kadootje',
      'Size': '{Width=706, Height=1012}',
      'Order': 56,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-65.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:26:51',
      'LastModified': '05/09/2017 07:26:51',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 66,
      'Title': 'Ring',
      'Size': '{Width=620, Height=1001}',
      'Order': 57,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-66.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:27:01',
      'LastModified': '05/09/2017 07:27:01',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 67,
      'Title': 'Unicorn',
      'Size': '{Width=1020, Height=947}',
      'Order': 58,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-67.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:27:09',
      'LastModified': '05/09/2017 07:27:09',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 68,
      'Title': 'Bowtie',
      'Size': '{Width=1017, Height=514}',
      'Order': 59,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-68.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:27:21',
      'LastModified': '05/09/2017 07:27:21',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 69,
      'Title': 'Confetti',
      'Size': '{Width=333, Height=995}',
      'Order': 60,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-69.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:27:33',
      'LastModified': '05/09/2017 07:27:33',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 70,
      'Title': 'Unicorn',
      'Size': '{Width=898, Height=1006}',
      'Order': 61,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-70.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:27:42',
      'LastModified': '05/09/2017 07:27:42',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 71,
      'Title': 'Lips',
      'Size': '{Width=1027, Height=573}',
      'Order': 62,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-71.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:27:55',
      'LastModified': '05/09/2017 07:27:55',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 72,
      'Title': 'Kitty',
      'Size': '{Width=797, Height=985}',
      'Order': 63,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-72.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:28:07',
      'LastModified': '05/09/2017 07:28:07',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 73,
      'Title': 'Mr',
      'Size': '{Width=1038, Height=932}',
      'Order': 64,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-73.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:28:18',
      'LastModified': '05/09/2017 07:28:18',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 74,
      'Title': 'Kitty',
      'Size': '{Width=708, Height=990}',
      'Order': 65,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-74.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:28:32',
      'LastModified': '05/09/2017 07:28:32',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 75,
      'Title': 'Ms',
      'Size': '{Width=1031, Height=971}',
      'Order': 66,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-75.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:28:42',
      'LastModified': '05/09/2017 07:28:42',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 76,
      'Title': 'Rainbow',
      'Size': '{Width=1010, Height=659}',
      'Order': 67,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-76.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:28:56',
      'LastModified': '05/09/2017 07:28:56',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 77,
      'Title': 'Champagne',
      'Size': '{Width=341, Height=1006}',
      'Order': 68,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-77.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:29:06',
      'LastModified': '05/09/2017 07:29:06',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 78,
      'Title': 'Hartjes',
      'Size': '{Width=951, Height=571}',
      'Order': 69,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-78.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:29:26',
      'LastModified': '05/09/2017 07:29:26',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 79,
      'Title': 'You are just lovely',
      'Size': '{Width=987, Height=899}',
      'Order': 70,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-79.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:29:40',
      'LastModified': '05/09/2017 07:29:40',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 80,
      'Title': 'Medaille',
      'Size': '{Width=591, Height=1018}',
      'Order': 71,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-80.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:29:51',
      'LastModified': '05/09/2017 07:29:51',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 81,
      'Title': 'Pluk de dag',
      'Size': '{Width=509, Height=1042}',
      'Order': 72,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-81.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:30:01',
      'LastModified': '05/09/2017 07:30:01',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 82,
      'Title': 'Zon',
      'Size': '{Width=1033, Height=1005}',
      'Order': 73,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-82.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:30:11',
      'LastModified': '05/09/2017 07:30:11',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 83,
      'Title': 'Crown',
      'Size': '{Width=997, Height=646}',
      'Order': 74,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-83.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:30:28',
      'LastModified': '05/09/2017 07:30:28',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 84,
      'Title': 'Hartjesdoos mama',
      'Size': '{Width=929, Height=974}',
      'Order': 75,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-84.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:30:41',
      'LastModified': '05/09/2017 07:30:41',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 85,
      'Title': 'Hartjesdoos',
      'Size': '{Width=929, Height=974}',
      'Order': 76,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-85.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:30:54',
      'LastModified': '05/09/2017 07:30:54',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 86,
      'Title': 'Wolk rain',
      'Size': '{Width=1002, Height=948}',
      'Order': 77,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-86.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:31:05',
      'LastModified': '05/09/2017 07:31:05',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 87,
      'Title': 'Crown',
      'Size': '{Width=1025, Height=721}',
      'Order': 78,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-87.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:31:32',
      'LastModified': '05/09/2017 07:31:32',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 88,
      'Title': 'Hartje mama',
      'Size': '{Width=886, Height=1004}',
      'Order': 79,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-88.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:31:42',
      'LastModified': '05/09/2017 07:31:42',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 89,
      'Title': 'Hartje',
      'Size': '{Width=886, Height=1004}',
      'Order': 80,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-89.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:40:00',
      'LastModified': '05/09/2017 07:40:00',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 90,
      'Title': 'Wolk donder',
      'Size': '{Width=878, Height=998}',
      'Order': 81,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-90.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:40:14',
      'LastModified': '05/09/2017 07:40:14',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 91,
      'Title': 'Enjoy',
      'Size': '{Width=1013, Height=1013}',
      'Order': 82,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-91.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:40:44',
      'LastModified': '05/09/2017 07:40:44',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 92,
      'Title': 'Monster',
      'Size': '{Width=768, Height=1013}',
      'Order': 83,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-92.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:40:54',
      'LastModified': '05/09/2017 07:40:54',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 93,
      'Title': 'Monster',
      'Size': '{Width=1009, Height=1022}',
      'Order': 84,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-93.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:41:04',
      'LastModified': '05/09/2017 07:41:04',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 94,
      'Title': 'Zwemband',
      'Size': '{Width=1042, Height=1042}',
      'Order': 85,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-94.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:41:17',
      'LastModified': '05/09/2017 07:41:17',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 95,
      'Title': 'Plons',
      'Size': '{Width=1033, Height=430}',
      'Order': 86,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-95.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:41:29',
      'LastModified': '05/09/2017 07:41:29',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 96,
      'Title': 'Zwemdiploma',
      'Size': '{Width=1019, Height=121}',
      'Order': 87,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-96.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:41:41',
      'LastModified': '05/09/2017 07:41:41',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 97,
      'Title': 'Geslaagd',
      'Size': '{Width=719, Height=1044}',
      'Order': 88,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-97.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:41:52',
      'LastModified': '05/09/2017 07:41:52',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 98,
      'Title': 'New home',
      'Size': '{Width=835, Height=1042}',
      'Order': 89,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-98.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:42:02',
      'LastModified': '05/09/2017 07:42:02',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 99,
      'Title': 'Sportheld',
      'Size': '{Width=925, Height=1040}',
      'Order': 90,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-99.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:42:12',
      'LastModified': '05/09/2017 07:42:12',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 100,
      'Title': 'Hoera',
      'Size': '{Width=855, Height=1042}',
      'Order': 91,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-100.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:42:21',
      'LastModified': '05/09/2017 07:42:21',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 101,
      'Title': 'Present',
      'Size': '{Width=714, Height=1004}',
      'Order': 92,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-101.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:42:31',
      'LastModified': '05/09/2017 07:42:31',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 102,
      'Title': 'Bird',
      'Size': '{Width=1031, Height=720}',
      'Order': 93,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-102.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:42:40',
      'LastModified': '10/11/2022 14:35:16',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 103,
      'Title': 'Snowflake',
      'Size': '{Width=922, Height=1000}',
      'Order': 94,
      'Categories': [
        10
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-103.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:42:53',
      'LastModified': '05/09/2017 07:42:53',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 104,
      'Title': 'Kado',
      'Size': '{Width=1415, Height=1533}',
      'Order': 95,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-104.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:42:57',
      'LastModified': '05/09/2017 07:42:57',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 105,
      'Title': 'Kado',
      'Size': '{Width=1415, Height=1533}',
      'Order': 96,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-105.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:43:07',
      'LastModified': '10/11/2022 14:35:26',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 106,
      'Title': 'Bat',
      'Size': '{Width=1042, Height=315}',
      'Order': 97,
      'Categories': [
        10
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-106.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:43:21',
      'LastModified': '05/09/2017 07:43:21',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 107,
      'Title': 'Hoera een jongen',
      'Size': '{Width=734, Height=1042}',
      'Order': 98,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-107.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:43:32',
      'LastModified': '05/09/2017 07:43:32',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 108,
      'Title': 'Bedankt',
      'Size': '{Width=1042, Height=1042}',
      'Order': 99,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-108.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:43:47',
      'LastModified': '10/11/2022 14:35:43',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 109,
      'Title': 'Pumpkin',
      'Size': '{Width=1042, Height=763}',
      'Order': 100,
      'Categories': [
        10
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-109.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:44:00',
      'LastModified': '05/09/2017 07:44:00',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 110,
      'Title': 'Stoplicht',
      'Size': '{Width=288, Height=1032}',
      'Order': 101,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-110.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:44:14',
      'LastModified': '05/09/2017 07:44:14',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 111,
      'Title': 'Hoera rijbewijs',
      'Size': '{Width=1040, Height=632}',
      'Order': 102,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-111.png',
      'Image': null
    },
    {
      'Created': '05/09/2017 07:44:25',
      'LastModified': '05/09/2017 07:44:25',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'Active': 1,
      'StickerId': 112,
      'Title': 'Geslaagd',
      'Size': '{Width=997, Height=997}',
      'Order': 103,
      'Categories': [
        4
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-112.png',
      'Image': null
    },
    {
      'Created': '06/08/2022 12:56:49',
      'LastModified': '06/08/2022 13:05:40',
      'LastModifiedBy': 'ad-acc\\anouk.hollebeek',
      'Active': 2,
      'ActiveStartDate': '06/08/2022 00:00:00',
      'ActiveEndDate': '06/21/2022 00:00:00',
      'StickerId': 113,
      'Title': 'super dad - vaderdag',
      'Size': '{Width=1000, Height=1000}',
      'Order': 104,
      'Categories': [
        11
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        17,
        18,
        19,
        21,
        22,
        23,
        24,
        25
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-113.png',
      'Image': null
    },
    {
      'Created': '06/08/2022 13:01:08',
      'LastModified': '06/08/2022 13:05:52',
      'LastModifiedBy': 'ad-acc\\anouk.hollebeek',
      'Active': 2,
      'ActiveStartDate': '06/08/2022 00:00:00',
      'ActiveEndDate': '06/21/2022 00:00:00',
      'StickerId': 114,
      'Title': 'fijne vaderdag',
      'Size': '{Width=1000, Height=1000}',
      'Order': 105,
      'Categories': [
        11
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        17,
        18,
        19,
        21,
        22,
        23,
        24,
        25
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-114.png',
      'Image': null
    },
    {
      'Created': '10/11/2022 14:04:52',
      'LastModified': '10/11/2022 14:04:52',
      'LastModifiedBy': 'ad-acc\\anouk.hollebeek',
      'Active': 1,
      'StickerId': 115,
      'Title': 'halloween-1',
      'Size': '{Width=1000, Height=1000}',
      'Order': 106,
      'Categories': [
        10
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        17,
        18,
        19,
        21,
        22,
        23,
        24,
        25,
        26
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-115.png',
      'Image': null
    },
    {
      'Created': '10/11/2022 14:34:38',
      'LastModified': '10/11/2022 14:35:01',
      'LastModifiedBy': 'ad-acc\\anouk.hollebeek',
      'Active': 1,
      'StickerId': 116,
      'Title': 'halloween-2',
      'Size': '{Width=1000, Height=1000}',
      'Order': 107,
      'Categories': [
        10
      ],
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        17,
        18,
        19,
        21,
        22,
        23,
        24,
        25,
        26
      ],
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-116.png',
      'Image': null
    }
  ];
}

export function getStickerDetail() {
  return {
    'Created': '05/09/2017 07:12:56',
    'LastModified': '06/18/2019 13:41:33',
    'LastModifiedBy': 'HEMALOCAL\\molenkampm',
    'Active': 1,
    'StickerId': 9,
    'Title': 'Happy',
    'Size': '{Width=1034, Height=571}',
    'Order': 2,
    'Categories': [
      4
    ],
    'ProductTypes': [
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      25
    ],
    'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/stickers/sticker-9.png',
    'Image': null
  };
}