import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ACTIVE_STATES } from '../../components/common/Enums';
import { Routes } from '../../components/common/UrlUtils';
import { ValidationErrorMessage } from '../../components/common/VlidationErrorMessage';
import { TextValuePairOptions } from '../../components/common/TextValuePairOptions';
import { ActiveDatePicker } from '../../components/common/ActiveDatePicker';
import { validateFormWithCustomMessages } from '../../utils/validation';
import LayoutTemplateService from '../../services/LayoutTemplateService';
import Spinner from '../../components/common/Spinner';
import ServerError from '../../components/states/ServerError';
import ShapeService from '../../services/ShapeService';
import ProductTypeService from '../../services/ProductTypeService';
import { appendIfExists, getPreviewImage } from '../../utils/dataUtils';
import { AlertMessage } from '../../utils/AlertMessage';
import { getLoggedUserName } from '../../utils/authUtils';
import { PageUtils } from '../PageUtils';

export const LayoutUpsertPage = () => {
  const FIELD_SINGULAR = 'layout template';
  const FIELD_PLURAL = 'layout templates';
  const history = useHistory();
  const { layoutId } = useParams();
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isFormValid, setIsFormValid] = useState(isEdit());
  const [isServerError, setIsServerError] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    message: '',
    type: '',
  });
  const [item, setItem] = useState({
    LayoutTemplateId: {
      value: '',
    },
    File: {
      value: [],
      errorMessage: '',
    },
    Title: {
      value: '',
      errorMessage: '',
    },
    TemplateXml: {
      value: '',
      errorMessage: '',
    },
    ProductTypes: {
      values: [],
      errorMessage: '',
    },
    ShapeId: {
      value: '',
      errorMessage: '',
    },
    PermittedSides: {
      value: '',
      errorMessage: '',
    },
    Active: {
      value: ACTIVE_STATES.Yes,
      errorMessage: '',
    },
    ActiveStartDate: {
      value: '',
      errorMessage: '',
    },
    ActiveEndDate: {
      value: '',
      errorMessage: '',
    },
    LastModified: {
      value: '',
      errorMessage: '',
    },
    LastModifiedBy: {
      value: '',
      errorMessage: '',
    },
    ImageUrl: {
      value: '',
      errorMessage: '',
    }
  });

  const [data, setData] = useState({
    enableSection: false,
    productTypes: [],
    shapes: [],
    permittedSides: [
      {
        value: 'left',
        text: 'Left',
      },
      {
        value: 'top',
        text: 'Top',
      },
      {
        value: 'right',
        text: 'Right',
      },
      {
        value: 'bottom',
        text: 'Bottom',
      },
      {
        value: 'center',
        text: 'Center'

      }
    ],
  });

  useEffect(() => {
    if (isLoading) {
      fetchData();
      setIsLoading(false);
    }

    async function fetchData() {
      if (isEdit()) {
        const [layoutTemplate, shapes, productTypes] = await Promise.all([
          LayoutTemplateService.getById(layoutId),
          ShapeService.getAll(),
          ProductTypeService.getAll(),
        ]);
        prepareData(layoutTemplate, shapes, productTypes);
      } else {
        const [shapes, productTypes] = await Promise.all([
          ShapeService.getAll(),
          ProductTypeService.getAll(),
        ]);
        prepareData(null, shapes, productTypes);
      }

      setIsLoading(false);
    }
  }, [item, isFormDisabled, isLoading, alertMessage, isServerError]);

  function prepareData(layoutTemplate, shapes, productTypes) {
    if ((layoutTemplate && layoutTemplate.error) || shapes.error || productTypes.error) {
      setIsServerError(true);
      return;
    }

    if (layoutTemplate) {
      // change comma string to array
      layoutTemplate.data.PermittedSides = layoutTemplate.data.PermittedSides ? layoutTemplate.data.PermittedSides.split(',') : [];
      const copyItem = { ...item };
      Object.keys(copyItem).forEach(key => {
        if (copyItem[key].values) {
          copyItem[key].values = layoutTemplate.data[key] || [];
        } else {
          copyItem[key].value = layoutTemplate.data[key];
        }
      });
      setItem(copyItem);
    }

    setData({
      ...data,
      shapes: shapes.data.map(shape => { return { value: shape.ShapeId, text: shape.Name }; }),
      productTypes: productTypes.data.map(productType => { return { value: productType.ProductTypeId, text: productType.Name }; }),
    }
    );
  }

  return (
    <>
      {PageUtils.getUpsertPageHeader(FIELD_PLURAL, isEdit(), Routes.layout.index)}
      {AlertMessage.getContent(alertMessage)}
      {getFormContent()}
    </>
  );

  function isEdit() {
    return layoutId !== undefined;
  }

  function getFormContent() {
    if (isLoading) {
      return (<Spinner />);
    }

    if (isServerError) {
      return (<ServerError />);
    }

    return (
      <form className="form-horizontal" onSubmit={onSubmitHandler}>
        <fieldset disabled={isFormDisabled}>
          <div className="form-horizontal">
            {getLastModifiedContent()}
            <div className="form-group">
              <div className="col-sm-2 text-right">
                <img
                  className="img-responsive thumb thumb-hover"
                  src={getPreviewImage(item.ImageUrl.value)} />
              </div>
              <div className="col-md-5">
                <input
                  required={!isEdit()}
                  data-val-name="Image"
                  type="file"
                  name="File"
                  accept="image/*"
                  className="btn btn-default"
                  onInput={onInputChangeHandler}
                  onInvalid={onInvalidHandler} />
                <ValidationErrorMessage errorMessage={item.File.errorMessage} />
              </div>
            </div>

            <div className="form-group">
              <label className="control-label col-md-2" for="Title">Template ID</label>
              <div className="col-md-5">
                <input
                  required={true}
                  data-val-name="Title"
                  className="form-control text-box single-line"
                  name="Title"
                  type="text"
                  defaultValue={item.Title.value}
                  onInput={onInputChangeHandler}
                  onBlur={onInputChangeHandler}
                  onInvalid={onInvalidHandler} />
                <ValidationErrorMessage errorMessage={item.Title.errorMessage} />
              </div>
            </div>

            <div className="form-group">
              <label className="control-label col-md-2">Sections</label>
              <div className="col-md-5">
                <textarea
                  className="form-control"
                  disabled={data.enableSection}
                  rows="3"
                  name="TemplateXml"
                  onInput={onInputChangeHandler}
                  onBlur={onInputChangeHandler}
                  onInvalid={onInvalidHandler}
                  defaultValue={item.TemplateXml.value}></textarea>
                <ValidationErrorMessage errorMessage={item.TemplateXml.errorMessage} />
              </div>
            </div>

            <div className="form-group">
              <label className="control-label col-md-2">ProductTypes</label>
              <div className="col-md-5">
                <select
                  className="form-control input-sm"
                  required={true}
                  data-val-name="Type(s)"
                  multiple="multiple"
                  name="ProductTypes"
                  onInput={onInputChangeHandler}
                  onBlur={onInputChangeHandler}
                  onInvalid={onInvalidHandler}>
                  <TextValuePairOptions items={data.productTypes} selectedValues={item.ProductTypes.values} />
                </select>
              </div>
            </div>
            <div className="form-group">
              <label className="col-sm-2 control-label">Shape</label>
              <div className="col-sm-5">
                <select
                  className="form-control input-sm"
                  required={true}
                  data-val-name="Shape"
                  name="ShapeId"
                  onInput={onInputChangeHandler}
                  onBlur={onInputChangeHandler}
                  onInvalid={onInvalidHandler}>
                  <TextValuePairOptions items={data.shapes} selectedValues={item.ShapeId.value ? [item.ShapeId.value] : []} />
                </select>
              </div>
            </div>
            <div className="form-group">
              <label className="col-sm-2 control-label">Permitted Sides</label>
              <div className="col-sm-5">
                <select className="form-control input-sm"
                  name="PermittedSides"
                  multiple={true}
                  onInput={onInputChangeHandler}
                  onBlur={onInputChangeHandler}
                  onInvalid={onInvalidHandler}>
                  <TextValuePairOptions items={data.permittedSides} selectedValues={item.PermittedSides.value ? [item.PermittedSides.value] : []} />
                </select>
              </div>
            </div>

            <ActiveDatePicker
              key={'active_date_picker'}
              activeState={item.Active.value}
              activeStartDate={item.ActiveStartDate.value}
              activeEndDate={item.ActiveEndDate.value}
              onActiveStateChange={onActiveStateChangeHandler}
              onDateChange={onDateChangeHandler}
            />

            <div className="form-group">
              <div className="col-sm-offset-2 col-sm-10">
                <button className="btn btn-primary" type="submit">Save</button>
              </div>
            </div>
          </div>
        </fieldset>
      </form>
    );
  };

  function getLastModifiedContent() {
    if (!isEdit()) {
      return null;
    }

    return (
      <div className="form-group">
        <label className="control-label col-md-2" for="LastModified">Last Modified</label>
        <div className="col-md-10">
          <p className="form-control-static">{item.LastModified.value} by {item.LastModifiedBy.value}</p>
        </div>
      </div>
    );
  }

  function onInputChangeHandler(event) {
    const { result, valid } = validateFormWithCustomMessages(item, event);
    setIsFormValid(valid);

    if (event.target.name === 'File' && event.target.files && event.target.files.length) {
      result.ImageUrl.value = URL.createObjectURL(event.target.files[0]);
    }

    setItem(result);
  }

  function onInvalidHandler(event) {
    // event.preventDefault();
  }

  function onActiveStateChangeHandler(newState) {
    const copy = { ...item };
    copy.Active.value = newState;
    setItem(copy);
  }

  function onDateChangeHandler(date, field) {
    const copy = { ...item };
    copy[field].value = date;
    setItem(copy);
  }

  function onSubmitHandler(event) {
    event.preventDefault();
    if (!isFormValid) {
      setAlertMessage(AlertMessage.getInvalidFormMessage());
      return;
    }

    if (isEdit()) {
      updateLayoutTemplate();
    } else {
      saveLayoutTemplate();
    }
  }

  // TODO: Align Swagger request with the real request
  async function updateLayoutTemplate() {
    setIsFormDisabled(true);
    const title = item.Title.value;
    const formData = new FormData();
    appendIfExists('LayoutTemplateId', item.LayoutTemplateId.value, formData);
    appendIfExists('Image', item.File.value, formData);
    appendIfExists('Title', item.Title.value, formData);
    appendIfExists('TemplateXml', item.TemplateXml.value, formData);
    item.ProductTypes.values.forEach(productId => appendIfExists('ProductTypeIds[]', productId, formData));
    appendIfExists('ShapeId', item.ShapeId.value, formData);
    appendIfExists('PermittedSides', item.PermittedSides.value, formData);
    appendIfExists('Active', '' + item.Active.value, formData);
    appendIfExists('ActiveStartDate', item.ActiveStartDate.value, formData);
    appendIfExists('ActiveEndDate', item.ActiveEndDate.value, formData);
    appendIfExists('LastModifiedBy', getLoggedUserName(), formData);

    const result = await LayoutTemplateService.update(layoutId, formData);
    if (result.error) {
      setIsFormDisabled(false);
      setAlertMessage(AlertMessage.getSaveItemErrorMessage(FIELD_SINGULAR, title, result));
      return;
    }
    setAlertMessage(AlertMessage.getSaveItemSuccessMessage(FIELD_SINGULAR, title));
    setTimeout(() => history.push(Routes.layout.index), 1000);
  }

  async function saveLayoutTemplate() {
    setIsFormDisabled(true);
    const title = item.Title.value;
    const formData = new FormData();
    appendIfExists('Image', item.File.value, formData);
    appendIfExists('Title', item.Title.value, formData);
    appendIfExists('TemplateXml', item.TemplateXml.value, formData);
    item.ProductTypes.values.forEach(productId => appendIfExists('ProductTypeIds[]', productId, formData));
    appendIfExists('ShapeId', item.ShapeId.value, formData);
    appendIfExists('PermittedSides', item.PermittedSides.value, formData);
    appendIfExists('Active', '' + item.Active.value, formData);
    appendIfExists('ActiveStartDate', item.ActiveStartDate.value, formData);
    appendIfExists('ActiveEndDate', item.ActiveEndDate.value, formData);
    appendIfExists('LastModifiedBy', getLoggedUserName(), formData);

    const result = await LayoutTemplateService.save(formData);
    setIsFormDisabled(false);
    if (result.error) {
      setAlertMessage(AlertMessage.getSaveItemErrorMessage(FIELD_SINGULAR, title, result));
      return;
    }
    setAlertMessage(AlertMessage.getSaveItemSuccessMessage(FIELD_SINGULAR, title));
    setTimeout(() => history.push(Routes.layout.index), 1000);
  }
};
