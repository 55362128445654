const MESSAGE_TYPE = {
  SUCCESS: 'alert-success',
  DANGER: 'alert-danger',
  WARNING: 'alert-warning',
};
export class AlertMessage {

  static getContent(alertMessage) {
    if (!alertMessage.message) {
      return null;
    }

    return (
      <div class={`alert ${alertMessage.type}`} role="alert">
        {alertMessage.message}
      </div>
    );
  }

  // **************************************************************************************
  // Save Messages
  // **************************************************************************************

  // Success
  static getSaveItemSuccessMessage(entityName, label) {
    return {
      message: (<div><strong>Well done!</strong> {label ? 'The' : 'A'} {entityName}{label ? ` ${label}` : ''} is saved successfully</div>),
      type: MESSAGE_TYPE.SUCCESS,
    };
  }

  static getSaveItemsSuccessMessage(numberOfSavedItems, entitySingular, entityPlural) {
    return {
      message: (<div><strong>Well done!</strong> {numberOfSavedItems} {numberOfSavedItems === 1 ? `${entitySingular} is` : `${entityPlural} are`} saved successfully</div>),
      type: MESSAGE_TYPE.SUCCESS,
    };
  }

  // Error
  static getSaveItemErrorMessage(entityName, label, result) {
    return {
      message: (<>Error while saving {label ? 'the' : 'a'} {entityName}{label ? ` ${label}` : ''}. {this.getErrorDescription(result)}</>),
      type: MESSAGE_TYPE.DANGER,
    };
  }

  static getSaveItemsErrorMessage(numberOfDeletedItems, entitySingular, entityPlural, serverError) {
    return {
      message: (<>Error while saving {numberOfDeletedItems} {numberOfDeletedItems === 1 ? entitySingular : entityPlural}. {this.getErrorDescription(serverError)}</>),
      type: MESSAGE_TYPE.DANGER,
    };
  }

  // **************************************************************************************
  // Delete Messages
  // **************************************************************************************

  // Success
  static getDeleteItemsSuccessMessage(numberOfDeletedItems, entitySingular, entityPlural) {
    return {
      message: (<div><strong>Well done!</strong> {numberOfDeletedItems} {numberOfDeletedItems === 1 ? `${entitySingular} is` : `${entityPlural} are`} deleted successfully</div>),
      type: MESSAGE_TYPE.SUCCESS,
    };
  }


  static getDeleteItemSuccessMessage(entityName, label) {
    return {
      message: (<div><strong>Well done!</strong> {label ? 'The' : 'A'} {entityName}{label ? ` ${label}` : ''} is deleted successfully</div>),
      type: MESSAGE_TYPE.SUCCESS,
    };
  }

  // Error
  static getDeleteItemsErrorMessage(numberOfDeletedItems, entitySingular, entityPlural, serverError) {
    return {
      message: (<>Error while deleting {numberOfDeletedItems} {numberOfDeletedItems === 1 ? entitySingular : entityPlural}. {this.getErrorDescription(serverError)}</>),
      type: MESSAGE_TYPE.DANGER,
    };
  }

  static getDeleteItemErrorMessage(entityName, label, serverError) {
    return {
      message: (<>Error while deleting {label ? 'the' : 'a'} {entityName}{label ? ` ${label}` : ''}. {this.getErrorDescription(serverError)}</>),
      type: MESSAGE_TYPE.DANGER,
    };
  }


  // **************************************************************************************
  // Reorder Messages
  // **************************************************************************************
  static getReorderItemSuccessMessage(entityName, label, order) {
    return {
      message: (<div><strong>Well done!</strong> {label ? 'The' : 'A'} {entityName}{label ? ` ${label}` : ''} is moved to the position {order}</div>),
      type: MESSAGE_TYPE.SUCCESS,
    };
  }

  static getReorderItemErrorMessage(entityName, label, order, serverError) {
    return {
      message: (<>Error while moving {label ? 'the' : 'a'} {entityName}{label ? ` ${label}` : ''} to the position {order}. {this.getErrorDescription(serverError)}</>),
      type: MESSAGE_TYPE.DANGER,
    };
  }

  // **************************************************************************************
  // Form Messages
  // **************************************************************************************
  static getInvalidFormMessage() {
    return {
      message: (<>Please fill all required fields in the form</>),
      type: MESSAGE_TYPE.WARNING,
    };
  }


  // **************************************************************************************
  // Utils
  // **************************************************************************************
  static getErrorDescription(result) {
    if (!result || !result.data) {
      return <></>;
    }

    if (result.data.error && result.data.error.message) {
      return (<>Error message: {result.data.error.message}</>);
    }

    if (result.data.Description) {
      return (<>Error code: {result.data.Description}</>);
    }

    return <></>;
  }
}
