import { DELETE, GET, POST, PUT } from './ApiService';
import appConfig from '../config/app-config';
import { getMasterDataMock } from './mocks/MasterDataServiceMock';

const baseUrl = `${appConfig.baseUri}/management/v1/MasterData`;
export default class MasterDateService {

  static async getAll() {
    if (appConfig.isTest) {
      return { data: getMasterDataMock(), error: false };
    }

    return GET({
      url: baseUrl,
      token: true,
    });
  }

  static async getById(id) {
    return GET({
      url: `${baseUrl}/${id}`,
      token: true,
    });
  }

  static async save(request) {
    return POST({
      url: baseUrl,
      token: true,
      body: request,
    });
  }

  static async addMasterData(type, value) {
    return POST({
      url: `${baseUrl}/MasterData/Add`,
      body: { type, value },
      token: true,
    });
  }

  static async update(id, request) {
    return PUT({
      url: `${baseUrl}/${id}`,
      token: true,
      body: request,
    });
  }

  static async deleteById(id) {
    return DELETE({
      url: `${baseUrl}/${id}`,
      token: true,
    });
  }

  static async updateOrder(itemId, order, operation) {
    return PUT({
      url: `${baseUrl}/UpdateOrder`,
      body: {
        ItemId: itemId,
        Order: order,
        Operation: operation,
      },
      token: true,
    });
  }
}
