/* eslint-disable */
export function getEditorOptionsMock() {
  return [
    {
      'Id': 2,
      'OptionValue': '[{\'id\':\'somebackendalignid1\',\'htmlClass\':\'font-ico-align-left\',\'label\':\'editor_panel_align_label_left\',\'align\':\'left\'},{\'id\':\'somebackendalignid2\',\'htmlClass\':\'font-ico-align-center\',\'label\':\'editor_panel_align_label_center\',\'align\':\'center\'},{\'id\':\'somebackendalignid3\',\'htmlClass\':\'font-ico-align-right\',\'label\':\'editor_panel_align_label_right\',\'align\':\'right\'}]',
      'OptionType': 'Alignment',
      'Created': '03/22/2017 07:35:46',
      'LastModified': '07/25/2023 13:43:46',
      'LastModifiedBy': null
    },
    {
      'Id': 3,
      'OptionValue': '{\'maxAmountOfCanvasItems\':15,\'maxAmountOfImages\':10,\'maxUploadFileSize\':10000000,\'photoUploadFieldAccept\':\'image/*\'}',
      'OptionType': 'Settings',
      'Created': '03/22/2017 07:35:53',
      'LastModified': '07/25/2023 13:44:07',
      'LastModifiedBy': null
    },
    {
      'Id': 5,
      'OptionValue': '{\'resize\':{\'min\':5,\'max\':500},\'crop\':{\'min\':5,\'max\':200},\'rotate\':{\'min\':-180,\'max\':180}}',
      'OptionType': 'Slider',
      'Created': '05/15/2023 21:48:48',
      'LastModified': '07/25/2023 13:43:17',
      'LastModifiedBy': null
    }
];
}