/* eslint-disable */
export function getAllLayoutTemplatesMock() {
  return [
    {
      'Active': 1,
      'Created': '02/22/2017 13:53:08',
      'LastModifiedBy': 'HEMALOCAL\\VriesE',
      'LayoutTemplateId': 2,
      'Title': 'Layout-03',
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/templates/template-2.svg',
      'TemplateXml': '{id: 1, accept: \'text\', dimension: {w: 100, h: 23.076923}},\r\n{id: 2, accept: \'photo\', dimension: {w: 100, h: 53.846154}},\r\n{id: 3, accept: \'text\', dimension: {w: 100, h: 23.076923}}',
      'Order': 4,
      'PermittedSides': 'center',
      'ShapeId': 2,
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'LastModified': '05/18/2017 12:10:03'
    },
    {
      'Active': 1,
      'Created': '03/22/2017 09:06:24',
      'LastModifiedBy': 'ad-acc\\asja.de.kruif',
      'LayoutTemplateId': 4,
      'Title': 'Layout-02',
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/templates/template-4.svg',
      'TemplateXml': '{id: 1, accept: \'photo\', dimension: {w: 50, h: 50}},\r\n{id: 2, accept: \'photo\', dimension: {w: 50, h: 50}},\r\n{id: 3, accept: \'photo\', dimension: {w: 100, h: 50}}',
      'Order': 3,
      'PermittedSides': 'center',
      'ShapeId': 2,
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        17,
        25
      ],
      'LastModified': '07/31/2019 15:11:24'
    },
    {
      'Active': 1,
      'Created': '03/22/2017 09:11:50',
      'LastModifiedBy': 'HEMALOCAL\\VriesE',
      'LayoutTemplateId': 5,
      'Title': 'Layout-04',
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/templates/template-5.svg',
      'TemplateXml': '{id: 1, accept: \'photo\', dimension: {w: 50, h: 50}},\r\n{id: 2, accept: \'photo\', dimension: {w: 50, h: 50}},\r\n{id: 3, accept: \'photo\', dimension: {w: 50, h: 50}},\r\n{id: 4, accept: \'photo\', dimension: {w: 50, h: 50}}',
      'Order': 5,
      'PermittedSides': 'center',
      'ShapeId': 2,
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'LastModified': '05/18/2017 12:10:06'
    },
    {
      'Active': 1,
      'Created': '04/20/2017 12:20:53',
      'LastModifiedBy': 'ad-acc\\jules.pasteuning',
      'LayoutTemplateId': 6,
      'Title': 'Layout-01',
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/templates/template-6.svg',
      'TemplateXml': '{id: 1, accept: \'photo\', dimension: {w: 100, h: 100}}',
      'Order': 2,
      'PermittedSides': 'center,top,right,bottom,left',
      'ShapeId': 2,
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        17,
        22,
        23,
        24,
        25,
        26
      ],
      'LastModified': '11/22/2022 16:08:53'
    },
    {
      'Active': 1,
      'Created': '04/27/2017 08:36:17',
      'LastModifiedBy': 'HEMALOCAL\\VriesE',
      'LayoutTemplateId': 7,
      'Title': 'Layout-05',
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/templates/template-7.svg',
      'TemplateXml': '{id: 1, accept: \'photo\', dimension: {w: 33.333333, h: 33.333333}},\r\n{id: 2, accept: \'photo\', dimension: {w: 33.333333, h: 33.333333}},\r\n{id: 3, accept: \'photo\', dimension: {w: 33.333333, h: 33.333333}},\r\n{id: 4, accept: \'photo\', dimension: {w: 33.333333, h: 33.333333}},\r\n{id: 5, accept: \'photo\', dimension: {w: 33.333333, h: 33.333333}},\r\n{id: 6, accept: \'photo\', dimension: {w: 33.333333, h: 33.333333}},\r\n{id: 7, accept: \'photo\', dimension: {w: 33.333333, h: 33.333333}},\r\n{id: 8, accept: \'photo\', dimension: {w: 33.333333, h: 33.333333}},\r\n{id: 9, accept: \'photo\', dimension: {w: 33.333333, h: 33.333333}}',
      'Order': 6,
      'PermittedSides': 'center',
      'ShapeId': 2,
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'LastModified': '05/18/2017 12:10:16'
    },
    {
      'Active': 1,
      'Created': '05/08/2017 09:48:38',
      'LastModifiedBy': 'HEMALOCAL\\VriesE',
      'LayoutTemplateId': 8,
      'Title': 'Layout-06',
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/templates/template-8.svg',
      'TemplateXml': '{id: 1, accept: \'photo\', dimension: {w: 33.333333, h: 33.333333}},\r\n{id: 2, accept: \'photo\', dimension: {w: 33.333333, h: 33.333333}},\r\n{id: 3, accept: \'photo\', dimension: {w: 33.333333, h: 33.333333}},\r\n{id: 4, accept: \'text\', dimension: {w: 100, h: 33.333333}},\r\n{id: 5, accept: \'photo\', dimension: {w: 33.333333, h: 33.333333}},\r\n{id: 6, accept: \'photo\', dimension: {w: 33.333333, h: 33.333333}},\r\n{id: 7, accept: \'photo\', dimension: {w: 33.333333, h: 33.333333}}',
      'Order': 7,
      'PermittedSides': 'center',
      'ShapeId': 2,
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'LastModified': '05/18/2017 12:10:12'
    },
    {
      'Active': 1,
      'Created': '05/08/2017 09:49:05',
      'LastModifiedBy': 'HEMALOCAL\\VriesE',
      'LayoutTemplateId': 9,
      'Title': 'Layout-07',
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/templates/template-9.svg',
      'TemplateXml': '{id: 1, accept: \'photo\', dimension: {w: 50, h: 66.666666}},\r\n{id: 2, accept: \'photo\', dimension: {w: 50, h: 66.666666}},\r\n{id: 3, accept: \'text\', dimension: {w: 100, h: 33.333333}}',
      'Order': 8,
      'PermittedSides': 'center',
      'ShapeId': 2,
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'LastModified': '05/18/2017 12:10:20'
    },
    {
      'Active': 1,
      'Created': '05/08/2017 09:55:15',
      'LastModifiedBy': 'ad-acc\\emsgak',
      'LayoutTemplateId': 11,
      'Title': 'Layout-00',
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/templates/template-11.svg',
      'TemplateXml': '',
      'Order': 0,
      'PermittedSides': 'center,top',
      'ShapeId': 2,
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        17,
        18,
        19,
        21,
        22,
        23,
        24,
        25,
        26
      ],
      'LastModified': '10/11/2022 10:01:18'
    },
    {
      'Active': 1,
      'Created': '05/08/2017 10:24:43',
      'LastModifiedBy': 'HEMALOCAL\\VriesE',
      'LayoutTemplateId': 12,
      'Title': 'Layout-08',
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/templates/template-12.svg',
      'TemplateXml': '{id: 1, accept: \'photo\', dimension: {w: 100, h: 50}},\r\n{id: 2, accept: \'photo\', dimension: {w: 100, h: 50}}',
      'Order': 9,
      'PermittedSides': 'center',
      'ShapeId': 2,
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'LastModified': '05/18/2017 12:10:25'
    },
    {
      'Active': 1,
      'Created': '05/08/2017 10:26:35',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'LayoutTemplateId': 13,
      'Title': 'Layout-09',
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/templates/template-13.svg',
      'TemplateXml': '{id: 1, accept: \'text\', dimension: {w: 50, h: 100}},\r\n{id: 2, accept: \'photo\', dimension: {w: 50, h: 100}}',
      'Order': 10,
      'PermittedSides': 'center,top,right,bottom',
      'ShapeId': 2,
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'LastModified': '06/16/2017 13:30:29'
    },
    {
      'Active': 1,
      'Created': '05/08/2017 10:27:44',
      'LastModifiedBy': 'HEMALOCAL\\VriesE',
      'LayoutTemplateId': 14,
      'Title': 'Layout-10',
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/templates/template-14.svg',
      'TemplateXml': '{id: 1, accept: \'photo\', dimension: {w: 50, h: 50}},\r\n{id: 2, accept: \'photo\', dimension: {w: 50, h: 50}},\r\n{id: 3, accept: \'text\', dimension: {w: 100, h: 50}}',
      'Order': 11,
      'PermittedSides': 'center',
      'ShapeId': 2,
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'LastModified': '05/18/2017 12:10:33'
    },
    {
      'Active': 1,
      'Created': '05/08/2017 10:28:12',
      'LastModifiedBy': 'HEMALOCAL\\VriesE',
      'LayoutTemplateId': 15,
      'Title': 'Layout-11',
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/templates/template-15.svg',
      'TemplateXml': '{id: 1, accept: \'photo\', dimension: {w: 100, h: 33.333333}},\r\n{id: 2, accept: \'photo\', dimension: {w: 100, h: 33.333333}},\r\n{id: 3, accept: \'text\', dimension: {w: 100, h: 33.333333}}',
      'Order': 12,
      'PermittedSides': 'center',
      'ShapeId': 2,
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'LastModified': '05/18/2017 12:10:38'
    },
    {
      'Active': 0,
      'Created': '05/08/2017 10:28:35',
      'LastModifiedBy': 'ad-acc\\asja.de.kruif',
      'LayoutTemplateId': 16,
      'Title': 'Layout-12',
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/templates/template-16.svg',
      'TemplateXml': '{id: \'hidden\', accept: \'\', dimension: {w: 100, h: 33.333333}},\r\n{id: 1, accept: \'text\', dimension: {w: 100, h: 33.333333}}',
      'Order': 13,
      'PermittedSides': 'center,top,right,bottom,left',
      'ShapeId': 2,
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        25
      ],
      'LastModified': '07/31/2019 15:15:43'
    },
    {
      'Active': 1,
      'Created': '05/08/2017 10:29:16',
      'LastModifiedBy': 'HEMALOCAL\\VriesE',
      'LayoutTemplateId': 17,
      'Title': 'Layout-13',
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/templates/template-17.svg',
      'TemplateXml': '{id: 1, accept: \'text\', dimension: {w: 100, h: 33.333333}},\r\n{id: 2, accept: \'photo\', dimension: {w: 100, h: 66.666666}}',
      'Order': 14,
      'PermittedSides': 'center',
      'ShapeId': 2,
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'LastModified': '05/18/2017 12:10:46'
    },
    {
      'Active': 1,
      'Created': '05/08/2017 10:29:41',
      'LastModifiedBy': 'HEMALOCAL\\VriesE',
      'LayoutTemplateId': 18,
      'Title': 'Layout-14',
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/templates/template-18.svg',
      'TemplateXml': '{id: 1, accept: \'photo\', dimension: {w: 33.333333, h: 50}},\r\n{id: 2, accept: \'photo\', dimension: {w: 33.333333, h: 50}},\r\n{id: 3, accept: \'photo\', dimension: {w: 33.333333, h: 50}},\r\n{id: 4, accept: \'photo\', dimension: {w: 33.333333, h: 50}},\r\n{id: 5, accept: \'photo\', dimension: {w: 33.333333, h: 50}},\r\n{id: 6, accept: \'photo\', dimension: {w: 33.333333, h: 50}}',
      'Order': 15,
      'PermittedSides': 'center',
      'ShapeId': 2,
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'LastModified': '05/18/2017 12:10:50'
    },
    {
      'Active': 1,
      'Created': '05/08/2017 10:30:07',
      'LastModifiedBy': 'HEMALOCAL\\emhcakirkan',
      'LayoutTemplateId': 19,
      'Title': 'Layout-15',
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/templates/template-19.svg',
      'TemplateXml': '{id: 1, accept: \'photo\', dimension: {w: 33.333333, h: 50}},\r\n{id: 2, accept: \'photo\', dimension: {w: 33.333333, h: 50}},\r\n{id: 3, accept: \'photo\', dimension: {w: 33.333333, h: 50}},\r\n{id: 4, accept: \'text\', dimension: {w: 100, h: 50}}',
      'Order': 16,
      'PermittedSides': 'center',
      'ShapeId': 2,
      'ProductTypes': [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13
      ],
      'LastModified': '06/07/2017 12:46:48'
    },
    {
      'Active': 1,
      'Created': '04/20/2017 12:20:53',
      'LastModifiedBy': 'ad-acc\\emmhijdra',
      'LayoutTemplateId': 20,
      'Title': 'Layout-01',
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/templates/template-6.svg',
      'TemplateXml': '{id: 1, accept: \'photo\', dimension: {w: 100, h: 100}}',
      'Order': 2,
      'PermittedSides': 'center,top,right,bottom,left',
      'ShapeId': 2,
      'ProductTypes': [],
      'LastModified': '05/27/2019 15:02:46'
    },
    {
      'Active': 1,
      'Created': '04/20/2017 12:20:53',
      'LastModifiedBy': 'ad-acc\\emmhijdra',
      'LayoutTemplateId': 21,
      'Title': 'Layout-01',
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/templates/template-6.svg',
      'TemplateXml': '{id: 1, accept: \'photo\', dimension: {w: 100, h: 100}}',
      'Order': 2,
      'PermittedSides': 'center,top,right,bottom,left',
      'ShapeId': 2,
      'ProductTypes': [],
      'LastModified': '05/27/2019 15:02:46'
    },
    {
      'Active': 1,
      'Created': '04/20/2017 12:20:53',
      'LastModifiedBy': 'ad-acc\\emmhijdra',
      'LayoutTemplateId': 22,
      'Title': 'Layout-01',
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/templates/template-6.svg',
      'TemplateXml': '{id: 1, accept: \'photo\', dimension: {w: 100, h: 100}}',
      'Order': 2,
      'PermittedSides': 'center,top,right,bottom,left',
      'ShapeId': 2,
      'ProductTypes': [],
      'LastModified': '05/27/2019 15:02:46'
    },
    {
      'Active': 1,
      'Created': '05/08/2017 09:55:15',
      'LastModifiedBy': 'ad-acc\\emmhijdra',
      'LayoutTemplateId': 23,
      'Title': 'Layout-00',
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/templates/template-11.svg',
      'TemplateXml': '{}',
      'Order': 0,
      'PermittedSides': 'center,top',
      'ShapeId': 2,
      'ProductTypes': [],
      'LastModified': '05/27/2019 15:02:36'
    },
    {
      'Active': 1,
      'Created': '05/08/2017 09:55:15',
      'LastModifiedBy': 'ad-acc\\emmhijdra',
      'LayoutTemplateId': 24,
      'Title': 'Layout-00',
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/templates/template-11.svg',
      'TemplateXml': '{}',
      'Order': 0,
      'PermittedSides': 'center,top',
      'ShapeId': 2,
      'ProductTypes': [],
      'LastModified': '05/27/2019 15:02:36'
    },
    {
      'Active': 1,
      'Created': '05/08/2017 09:55:15',
      'LastModifiedBy': 'ad-acc\\emmhijdra',
      'LayoutTemplateId': 25,
      'Title': 'Layout-00',
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/templates/template-11.svg',
      'TemplateXml': '{}',
      'Order': 0,
      'PermittedSides': 'center,top',
      'ShapeId': 2,
      'ProductTypes': [],
      'LastModified': '05/27/2019 15:02:36'
    },
    {
      'Active': 1,
      'Created': '05/08/2017 09:55:15',
      'LastModifiedBy': 'ad-acc\\emmhijdra',
      'LayoutTemplateId': 26,
      'Title': 'Layout-00',
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/templates/template-11.svg',
      'TemplateXml': '{}',
      'Order': 0,
      'PermittedSides': 'center,top',
      'ShapeId': 2,
      'ProductTypes': [],
      'LastModified': '05/27/2019 15:02:36'
    },
    {
      'Active': 1,
      'Created': '05/08/2017 09:55:15',
      'LastModifiedBy': 'ad-acc\\emsgak',
      'LayoutTemplateId': 27,
      'Title': 'Layout-00',
      'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/templates/template-11.svg',
      'TemplateXml': '',
      'Order': 0,
      'PermittedSides': 'center,top',
      'ShapeId': 2,
      'ProductTypes': [],
      'LastModified': '10/11/2022 10:01:18'
    }
  ];
}

export function getLayoutTemplateDetailMock() {
  return {
    'Active': 1,
    'Created': '05/08/2017 10:30:07',
    'LastModifiedBy': 'HEMALOCAL\\emhcakirkan',
    'LayoutTemplateId': 19,
    'Title': 'Layout-15',
    'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/templates/template-19.svg',
    'TemplateXml': '{id: 1, accept: \'photo\', dimension: {w: 33.333333, h: 50}},\r\n{id: 2, accept: \'photo\', dimension: {w: 33.333333, h: 50}},\r\n{id: 3, accept: \'photo\', dimension: {w: 33.333333, h: 50}},\r\n{id: 4, accept: \'text\', dimension: {w: 100, h: 50}}',
    'Order': 16,
    'PermittedSides': 'center,top',
    'ShapeId': 2,
    'ProductTypes': [
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13
    ],
    'LastModified': '06/07/2017 12:46:48'
  };
}