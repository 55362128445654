/* eslint-disable */
export function getAllFontsMock() {
  return [
    {
      'Active': 1,
      'FontId': 13,
      'Title': 'Barrio',
      'ImagePath': 'fonts/font-13.svg',
      'MaximumFontSize': 500,
      'MinimumFontSize': 18,
      'Order': 0,
      'CorrectionY': 0,
      'DefaultFontSize': 20,
      'LastModified': '10/11/2022 09:57:47',
      'LastModifiedBy': 'ad-acc\\emsgak',
      'FontFiles': [
        {
          'Id': 9,
          'FontId': 13,
          'FileName': 'BarrioRegular.ttf',
          'FilePath': 'https://acc-hema-tool-fonts.s3-eu-central-1.amazonaws.com/13-BarrioRegular.ttf',
          'Format': null,
          'MimeType': 'application/octet-stream',
          'CreatedOn': '05/08/2017 12:13:28'
        },
        {
          'Id': 10,
          'FontId': 13,
          'FileName': 'BarrioRegular.woff',
          'FilePath': 'https://acc-hema-tool-fonts.s3-eu-central-1.amazonaws.com/13-BarrioRegular.woff',
          'Format': null,
          'MimeType': 'application/font-woff',
          'CreatedOn': '05/08/2017 12:13:28'
        }
      ],
      'Categories': [
        {
          'CategoryId': 4,
          'Name': '01 Algemeen',
          'Order': 2,
          'Backgrounds': [],
          'Fonts': [],
          'Stickers': []
        }
      ],
      'Created': '05/08/2017 12:12:08',
      'ProductTypes': [
        {
          'ProductTypeId': 4,
          'Name': 'party-cake-1128x739',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 5,
          'Name': 'maxi-party-cake-1636x1099',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 6,
          'Name': 'heart-cake-1033x1010',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 7,
          'Name': 'mini-tompouce-cake-245x207',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 8,
          'Name': 'tompouce-cake-526x207',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 9,
          'Name': 'choco-ipad-cake-957x662',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 10,
          'Name': 'choco-iphone-cake-308x544',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 11,
          'Name': 'choco-heart-cake-993x762',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 12,
          'Name': 'lolly-s-cake-331x33x',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 13,
          'Name': 'choco-fotolist-cake-957x662',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 17,
          'Name': '3D-cake-1740x1740',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 22,
          'Name': 'wine-label-champagne',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 23,
          'Name': 'wine-label-red',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 24,
          'Name': 'wine-label-white',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 25,
          'Name': 'cupcake-319',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 26,
          'Name': 'round-cake-992',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        }
      ]
    },
    {
      'Active': 1,
      'FontId': 14,
      'Title': 'Sofia-Chique',
      'ImagePath': 'fonts/font-14.svg',
      'MaximumFontSize': 100,
      'MinimumFontSize': 16,
      'Order': 1,
      'CorrectionY': 0,
      'DefaultFontSize': 20,
      'LastModified': '10/11/2022 09:57:59',
      'LastModifiedBy': 'ad-acc\\emsgak',
      'FontFiles': [
        {
          'Id': 11,
          'FontId': 14,
          'FileName': 'Sofia.ttf',
          'FilePath': 'https://acc-hema-tool-fonts.s3-eu-central-1.amazonaws.com/14-Sofia.ttf',
          'Format': null,
          'MimeType': 'application/octet-stream',
          'CreatedOn': '05/08/2017 12:16:12'
        },
        {
          'Id': 12,
          'FontId': 14,
          'FileName': 'Sofia.woff',
          'FilePath': 'https://acc-hema-tool-fonts.s3-eu-central-1.amazonaws.com/14-Sofia.woff',
          'Format': null,
          'MimeType': 'application/font-woff',
          'CreatedOn': '05/08/2017 12:16:13'
        }
      ],
      'Categories': [
        {
          'CategoryId': 4,
          'Name': '01 Algemeen',
          'Order': 2,
          'Backgrounds': [],
          'Fonts': [],
          'Stickers': []
        }
      ],
      'Created': '05/08/2017 12:15:50',
      'ProductTypes': [
        {
          'ProductTypeId': 4,
          'Name': 'party-cake-1128x739',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 5,
          'Name': 'maxi-party-cake-1636x1099',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 6,
          'Name': 'heart-cake-1033x1010',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 7,
          'Name': 'mini-tompouce-cake-245x207',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 8,
          'Name': 'tompouce-cake-526x207',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 9,
          'Name': 'choco-ipad-cake-957x662',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 10,
          'Name': 'choco-iphone-cake-308x544',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 11,
          'Name': 'choco-heart-cake-993x762',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 12,
          'Name': 'lolly-s-cake-331x33x',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 13,
          'Name': 'choco-fotolist-cake-957x662',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 17,
          'Name': '3D-cake-1740x1740',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 22,
          'Name': 'wine-label-champagne',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 23,
          'Name': 'wine-label-red',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 24,
          'Name': 'wine-label-white',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 25,
          'Name': 'cupcake-319',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 26,
          'Name': 'round-cake-992',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        }
      ]
    },
    {
      'Active': 1,
      'FontId': 15,
      'Title': 'Amatic SC',
      'ImagePath': 'fonts/font-15.svg',
      'MaximumFontSize': 100,
      'MinimumFontSize': 18,
      'Order': 2,
      'CorrectionY': 0.2,
      'DefaultFontSize': 20,
      'LastModified': '10/11/2022 09:58:11',
      'LastModifiedBy': 'ad-acc\\emsgak',
      'FontFiles': [
        {
          'Id': 13,
          'FontId': 15,
          'FileName': 'AmaticSCRegular.ttf',
          'FilePath': 'https://acc-hema-tool-fonts.s3-eu-central-1.amazonaws.com/15-AmaticSCRegular.ttf',
          'Format': null,
          'MimeType': 'application/octet-stream',
          'CreatedOn': '05/08/2017 12:18:36'
        },
        {
          'Id': 14,
          'FontId': 15,
          'FileName': 'AmaticSCRegular.woff',
          'FilePath': 'https://acc-hema-tool-fonts.s3-eu-central-1.amazonaws.com/15-AmaticSCRegular.woff',
          'Format': null,
          'MimeType': 'application/font-woff',
          'CreatedOn': '05/08/2017 12:18:36'
        }
      ],
      'Categories': [
        {
          'CategoryId': 4,
          'Name': '01 Algemeen',
          'Order': 2,
          'Backgrounds': [],
          'Fonts': [],
          'Stickers': []
        }
      ],
      'Created': '05/08/2017 12:18:01',
      'ProductTypes': [
        {
          'ProductTypeId': 4,
          'Name': 'party-cake-1128x739',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 5,
          'Name': 'maxi-party-cake-1636x1099',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 6,
          'Name': 'heart-cake-1033x1010',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 7,
          'Name': 'mini-tompouce-cake-245x207',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 8,
          'Name': 'tompouce-cake-526x207',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 9,
          'Name': 'choco-ipad-cake-957x662',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 10,
          'Name': 'choco-iphone-cake-308x544',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 11,
          'Name': 'choco-heart-cake-993x762',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 12,
          'Name': 'lolly-s-cake-331x33x',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 13,
          'Name': 'choco-fotolist-cake-957x662',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 17,
          'Name': '3D-cake-1740x1740',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 26,
          'Name': 'round-cake-992',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        }
      ]
    },
    {
      'Active': 1,
      'FontId': 16,
      'Title': 'Graduate',
      'ImagePath': 'fonts/font-16.svg',
      'MaximumFontSize': 100,
      'MinimumFontSize': 14,
      'Order': 3,
      'CorrectionY': 0,
      'DefaultFontSize': 20,
      'LastModified': '06/28/2017 08:11:25',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'FontFiles': [
        {
          'Id': 15,
          'FontId': 16,
          'FileName': 'Graduate.ttf',
          'FilePath': 'https://acc-hema-tool-fonts.s3-eu-central-1.amazonaws.com/16-Graduate.ttf',
          'Format': null,
          'MimeType': 'application/octet-stream',
          'CreatedOn': '05/08/2017 12:20:13'
        },
        {
          'Id': 16,
          'FontId': 16,
          'FileName': 'Graduate.woff',
          'FilePath': 'https://acc-hema-tool-fonts.s3-eu-central-1.amazonaws.com/16-Graduate.woff',
          'Format': null,
          'MimeType': 'application/font-woff',
          'CreatedOn': '05/08/2017 12:20:13'
        }
      ],
      'Categories': [
        {
          'CategoryId': 4,
          'Name': '01 Algemeen',
          'Order': 2,
          'Backgrounds': [],
          'Fonts': [],
          'Stickers': []
        }
      ],
      'Created': '05/08/2017 12:19:55',
      'ProductTypes': [
        {
          'ProductTypeId': 4,
          'Name': 'party-cake-1128x739',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 5,
          'Name': 'maxi-party-cake-1636x1099',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 6,
          'Name': 'heart-cake-1033x1010',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 7,
          'Name': 'mini-tompouce-cake-245x207',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 8,
          'Name': 'tompouce-cake-526x207',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 9,
          'Name': 'choco-ipad-cake-957x662',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 10,
          'Name': 'choco-iphone-cake-308x544',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 11,
          'Name': 'choco-heart-cake-993x762',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 12,
          'Name': 'lolly-s-cake-331x33x',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 13,
          'Name': 'choco-fotolist-cake-957x662',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 17,
          'Name': '3D-cake-1740x1740',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        }
      ]
    },
    {
      'Active': 1,
      'FontId': 17,
      'Title': 'Montserrat',
      'ImagePath': 'fonts/font-17.svg',
      'MaximumFontSize': 200,
      'MinimumFontSize': 14,
      'Order': 5,
      'CorrectionY': 0,
      'DefaultFontSize': 20,
      'LastModified': '08/15/2017 12:04:47',
      'LastModifiedBy': 'HEMALOCAL\\emdvorobiova',
      'FontFiles': [
        {
          'Id': 17,
          'FontId': 17,
          'FileName': 'MontserratLight.ttf',
          'FilePath': 'https://acc-hema-tool-fonts.s3-eu-central-1.amazonaws.com/17-MontserratLight.ttf',
          'Format': null,
          'MimeType': 'application/octet-stream',
          'CreatedOn': '05/08/2017 12:21:19'
        },
        {
          'Id': 18,
          'FontId': 17,
          'FileName': 'MontserratLight.woff',
          'FilePath': 'https://acc-hema-tool-fonts.s3-eu-central-1.amazonaws.com/17-MontserratLight.woff',
          'Format': null,
          'MimeType': 'application/font-woff',
          'CreatedOn': '05/08/2017 12:21:19'
        }
      ],
      'Categories': [
        {
          'CategoryId': 4,
          'Name': '01 Algemeen',
          'Order': 2,
          'Backgrounds': [],
          'Fonts': [],
          'Stickers': []
        }
      ],
      'Created': '05/08/2017 12:21:02',
      'ProductTypes': [
        {
          'ProductTypeId': 4,
          'Name': 'party-cake-1128x739',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 5,
          'Name': 'maxi-party-cake-1636x1099',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 6,
          'Name': 'heart-cake-1033x1010',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 7,
          'Name': 'mini-tompouce-cake-245x207',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 8,
          'Name': 'tompouce-cake-526x207',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 9,
          'Name': 'choco-ipad-cake-957x662',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 10,
          'Name': 'choco-iphone-cake-308x544',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 11,
          'Name': 'choco-heart-cake-993x762',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 12,
          'Name': 'lolly-s-cake-331x33x',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 13,
          'Name': 'choco-fotolist-cake-957x662',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        }
      ]
    },
    {
      'Active': 1,
      'FontId': 18,
      'Title': 'Berkshire Swash',
      'ImagePath': 'fonts/font-18.svg',
      'MaximumFontSize': 200,
      'MinimumFontSize': 40,
      'Order': 6,
      'CorrectionY': 0,
      'DefaultFontSize': 80,
      'LastModified': '07/09/2018 16:57:32',
      'LastModifiedBy': 'ad-acc\\emtbakkum',
      'FontFiles': [
        {
          'Id': 19,
          'FontId': 18,
          'FileName': 'BerkshireSwash.ttf',
          'FilePath': 'https://acc-hema-tool-fonts.s3-eu-central-1.amazonaws.com/18-BerkshireSwash.ttf',
          'Format': null,
          'MimeType': 'application/octet-stream',
          'CreatedOn': '05/08/2017 12:22:11'
        },
        {
          'Id': 20,
          'FontId': 18,
          'FileName': 'BerkshireSwash.woff',
          'FilePath': 'https://acc-hema-tool-fonts.s3-eu-central-1.amazonaws.com/18-BerkshireSwash.woff',
          'Format': null,
          'MimeType': 'application/font-woff',
          'CreatedOn': '05/08/2017 12:22:11'
        }
      ],
      'Categories': [
        {
          'CategoryId': 4,
          'Name': '01 Algemeen',
          'Order': 2,
          'Backgrounds': [],
          'Fonts': [],
          'Stickers': []
        }
      ],
      'Created': '05/08/2017 12:21:55',
      'ProductTypes': [
        {
          'ProductTypeId': 4,
          'Name': 'party-cake-1128x739',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 5,
          'Name': 'maxi-party-cake-1636x1099',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 6,
          'Name': 'heart-cake-1033x1010',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 7,
          'Name': 'mini-tompouce-cake-245x207',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 8,
          'Name': 'tompouce-cake-526x207',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 9,
          'Name': 'choco-ipad-cake-957x662',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 10,
          'Name': 'choco-iphone-cake-308x544',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 11,
          'Name': 'choco-heart-cake-993x762',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 12,
          'Name': 'lolly-s-cake-331x33x',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 13,
          'Name': 'choco-fotolist-cake-957x662',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 17,
          'Name': '3D-cake-1740x1740',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        }
      ]
    },
    {
      'Active': 1,
      'FontId': 19,
      'Title': 'Playfair Display',
      'ImagePath': 'fonts/font-19.svg',
      'MaximumFontSize': 100,
      'MinimumFontSize': 16,
      'Order': 4,
      'CorrectionY': 0,
      'DefaultFontSize': 20,
      'LastModified': '06/28/2017 08:11:59',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'FontFiles': [
        {
          'Id': 21,
          'FontId': 19,
          'FileName': 'PlayfairDisplayBlack.ttf',
          'FilePath': 'https://acc-hema-tool-fonts.s3-eu-central-1.amazonaws.com/19-PlayfairDisplayBlack.ttf',
          'Format': null,
          'MimeType': 'application/octet-stream',
          'CreatedOn': '05/08/2017 12:22:59'
        },
        {
          'Id': 22,
          'FontId': 19,
          'FileName': 'PlayfairDisplayBlack.woff',
          'FilePath': 'https://acc-hema-tool-fonts.s3-eu-central-1.amazonaws.com/19-PlayfairDisplayBlack.woff',
          'Format': null,
          'MimeType': 'application/font-woff',
          'CreatedOn': '05/08/2017 12:22:59'
        }
      ],
      'Categories': [
        {
          'CategoryId': 4,
          'Name': '01 Algemeen',
          'Order': 2,
          'Backgrounds': [],
          'Fonts': [],
          'Stickers': []
        }
      ],
      'Created': '05/08/2017 12:22:45',
      'ProductTypes': [
        {
          'ProductTypeId': 4,
          'Name': 'party-cake-1128x739',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 5,
          'Name': 'maxi-party-cake-1636x1099',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 6,
          'Name': 'heart-cake-1033x1010',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 7,
          'Name': 'mini-tompouce-cake-245x207',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 8,
          'Name': 'tompouce-cake-526x207',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 9,
          'Name': 'choco-ipad-cake-957x662',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 10,
          'Name': 'choco-iphone-cake-308x544',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 11,
          'Name': 'choco-heart-cake-993x762',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 12,
          'Name': 'lolly-s-cake-331x33x',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 13,
          'Name': 'choco-fotolist-cake-957x662',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 17,
          'Name': '3D-cake-1740x1740',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        }
      ]
    },
    {
      'Active': 1,
      'FontId': 20,
      'Title': 'Gochi Hand',
      'ImagePath': 'fonts/font-20.svg',
      'MaximumFontSize': 100,
      'MinimumFontSize': 18,
      'Order': 7,
      'CorrectionY': 0,
      'DefaultFontSize': 20,
      'LastModified': '06/28/2017 08:12:17',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'FontFiles': [
        {
          'Id': 23,
          'FontId': 20,
          'FileName': 'GochiHand.ttf',
          'FilePath': 'https://acc-hema-tool-fonts.s3-eu-central-1.amazonaws.com/20-GochiHand.ttf',
          'Format': null,
          'MimeType': 'application/octet-stream',
          'CreatedOn': '05/08/2017 12:23:49'
        },
        {
          'Id': 24,
          'FontId': 20,
          'FileName': 'GochiHand.woff',
          'FilePath': 'https://acc-hema-tool-fonts.s3-eu-central-1.amazonaws.com/20-GochiHand.woff',
          'Format': null,
          'MimeType': 'application/font-woff',
          'CreatedOn': '05/08/2017 12:23:49'
        }
      ],
      'Categories': [
        {
          'CategoryId': 4,
          'Name': '01 Algemeen',
          'Order': 2,
          'Backgrounds': [],
          'Fonts': [],
          'Stickers': []
        }
      ],
      'Created': '05/08/2017 12:23:30',
      'ProductTypes': [
        {
          'ProductTypeId': 4,
          'Name': 'party-cake-1128x739',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 5,
          'Name': 'maxi-party-cake-1636x1099',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 6,
          'Name': 'heart-cake-1033x1010',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 7,
          'Name': 'mini-tompouce-cake-245x207',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 8,
          'Name': 'tompouce-cake-526x207',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 9,
          'Name': 'choco-ipad-cake-957x662',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 10,
          'Name': 'choco-iphone-cake-308x544',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 11,
          'Name': 'choco-heart-cake-993x762',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 12,
          'Name': 'lolly-s-cake-331x33x',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 13,
          'Name': 'choco-fotolist-cake-957x662',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 17,
          'Name': '3D-cake-1740x1740',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        }
      ]
    },
    {
      'Active': 1,
      'FontId': 21,
      'Title': 'Bangers',
      'ImagePath': 'fonts/font-21.svg',
      'MaximumFontSize': 100,
      'MinimumFontSize': 14,
      'Order': 8,
      'CorrectionY': 0.4,
      'DefaultFontSize': 20,
      'LastModified': '08/21/2018 12:59:46',
      'LastModifiedBy': 'ad-acc\\emmhijdra',
      'FontFiles': [
        {
          'Id': 25,
          'FontId': 21,
          'FileName': 'BangersRegular.ttf',
          'FilePath': 'https://acc-hema-tool-fonts.s3-eu-central-1.amazonaws.com/21-BangersRegular.ttf',
          'Format': null,
          'MimeType': 'application/octet-stream',
          'CreatedOn': '05/08/2017 12:24:45'
        },
        {
          'Id': 26,
          'FontId': 21,
          'FileName': 'BangersRegular.woff',
          'FilePath': 'https://acc-hema-tool-fonts.s3-eu-central-1.amazonaws.com/21-BangersRegular.woff',
          'Format': null,
          'MimeType': 'application/font-woff',
          'CreatedOn': '05/08/2017 12:24:45'
        }
      ],
      'Categories': [
        {
          'CategoryId': 4,
          'Name': '01 Algemeen',
          'Order': 2,
          'Backgrounds': [],
          'Fonts': [],
          'Stickers': []
        }
      ],
      'Created': '05/08/2017 12:24:31',
      'ProductTypes': [
        {
          'ProductTypeId': 4,
          'Name': 'party-cake-1128x739',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 5,
          'Name': 'maxi-party-cake-1636x1099',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 6,
          'Name': 'heart-cake-1033x1010',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 7,
          'Name': 'mini-tompouce-cake-245x207',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 8,
          'Name': 'tompouce-cake-526x207',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 9,
          'Name': 'choco-ipad-cake-957x662',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 10,
          'Name': 'choco-iphone-cake-308x544',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 11,
          'Name': 'choco-heart-cake-993x762',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 12,
          'Name': 'lolly-s-cake-331x33x',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 13,
          'Name': 'choco-fotolist-cake-957x662',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 17,
          'Name': '3D-cake-1740x1740',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        }
      ]
    },
    {
      'Active': 1,
      'FontId': 22,
      'Title': 'Bungee',
      'ImagePath': 'fonts/font-22.svg',
      'MaximumFontSize': 100,
      'MinimumFontSize': 14,
      'Order': 9,
      'CorrectionY': 0,
      'DefaultFontSize': 20,
      'LastModified': '06/22/2017 06:29:11',
      'LastModifiedBy': 'HEMALOCAL\\emhcakirkan',
      'FontFiles': [
        {
          'Id': 27,
          'FontId': 22,
          'FileName': 'BungeeRegular.ttf',
          'FilePath': 'https://acc-hema-tool-fonts.s3-eu-central-1.amazonaws.com/22-BungeeRegular.ttf',
          'Format': null,
          'MimeType': 'application/octet-stream',
          'CreatedOn': '05/08/2017 12:25:37'
        },
        {
          'Id': 28,
          'FontId': 22,
          'FileName': 'BungeeRegular.woff',
          'FilePath': 'https://acc-hema-tool-fonts.s3-eu-central-1.amazonaws.com/22-BungeeRegular.woff',
          'Format': null,
          'MimeType': 'application/font-woff',
          'CreatedOn': '05/08/2017 12:25:37'
        }
      ],
      'Categories': [
        {
          'CategoryId': 4,
          'Name': '01 Algemeen',
          'Order': 2,
          'Backgrounds': [],
          'Fonts': [],
          'Stickers': []
        }
      ],
      'Created': '05/08/2017 12:25:26',
      'ProductTypes': [
        {
          'ProductTypeId': 4,
          'Name': 'party-cake-1128x739',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 5,
          'Name': 'maxi-party-cake-1636x1099',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 6,
          'Name': 'heart-cake-1033x1010',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 7,
          'Name': 'mini-tompouce-cake-245x207',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 8,
          'Name': 'tompouce-cake-526x207',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 9,
          'Name': 'choco-ipad-cake-957x662',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 10,
          'Name': 'choco-iphone-cake-308x544',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 11,
          'Name': 'choco-heart-cake-993x762',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 12,
          'Name': 'lolly-s-cake-331x33x',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 13,
          'Name': 'choco-fotolist-cake-957x662',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        }
      ]
    },
    {
      'Active': 1,
      'FontId': 23,
      'Title': 'Times New Roman',
      'ImagePath': 'fonts/font-23.svg',
      'MaximumFontSize': 100,
      'MinimumFontSize': 16,
      'Order': 10,
      'CorrectionY': 0,
      'DefaultFontSize': 20,
      'LastModified': '06/28/2017 08:12:42',
      'LastModifiedBy': 'HEMALOCAL\\molenkampm',
      'FontFiles': [],
      'Categories': [
        {
          'CategoryId': 4,
          'Name': '01 Algemeen',
          'Order': 2,
          'Backgrounds': [],
          'Fonts': [],
          'Stickers': []
        }
      ],
      'Created': '05/08/2017 12:26:07',
      'ProductTypes': [
        {
          'ProductTypeId': 4,
          'Name': 'party-cake-1128x739',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 5,
          'Name': 'maxi-party-cake-1636x1099',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 6,
          'Name': 'heart-cake-1033x1010',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 7,
          'Name': 'mini-tompouce-cake-245x207',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 8,
          'Name': 'tompouce-cake-526x207',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 9,
          'Name': 'choco-ipad-cake-957x662',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 10,
          'Name': 'choco-iphone-cake-308x544',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 11,
          'Name': 'choco-heart-cake-993x762',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 12,
          'Name': 'lolly-s-cake-331x33x',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 13,
          'Name': 'choco-fotolist-cake-957x662',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 17,
          'Name': '3D-cake-1740x1740',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        }
      ]
    },
    {
      'Active': 1,
      'FontId': 24,
      'Title': 'Arial',
      'ImagePath': 'fonts/font-24.svg',
      'MaximumFontSize': 100,
      'MinimumFontSize': 14,
      'Order': 11,
      'CorrectionY': 0,
      'DefaultFontSize': 20,
      'LastModified': '05/27/2019 15:03:45',
      'LastModifiedBy': 'ad-acc\\emmhijdra',
      'FontFiles': [],
      'Categories': [
        {
          'CategoryId': 4,
          'Name': '01 Algemeen',
          'Order': 2,
          'Backgrounds': [],
          'Fonts': [],
          'Stickers': []
        }
      ],
      'Created': '05/08/2017 12:27:39',
      'ProductTypes': [
        {
          'ProductTypeId': 4,
          'Name': 'party-cake-1128x739',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 5,
          'Name': 'maxi-party-cake-1636x1099',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 6,
          'Name': 'heart-cake-1033x1010',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 7,
          'Name': 'mini-tompouce-cake-245x207',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 8,
          'Name': 'tompouce-cake-526x207',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 9,
          'Name': 'choco-ipad-cake-957x662',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 10,
          'Name': 'choco-iphone-cake-308x544',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 11,
          'Name': 'choco-heart-cake-993x762',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 12,
          'Name': 'lolly-s-cake-331x33x',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 13,
          'Name': 'choco-fotolist-cake-957x662',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 17,
          'Name': '3D-cake-1740x1740',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 18,
          'Name': 'giftcard-516x324',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 19,
          'Name': 'white-wine-921x1276',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 21,
          'Name': 'red-wine-921x1276',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        },
        {
          'ProductTypeId': 25,
          'Name': 'cupcake-319',
          'Backgrounds': [],
          'Fonts': [],
          'Products': [],
          'LayoutTemplates': [],
          'Stickers': []
        }
      ]
    }
  ];
}

export function getFontDetailMock() {
  return {
    'Active': 1,
    'CategoryIds': [
      4
    ],
    'CorrectionY': 0.4,
    'Created': '05/08/2017 12:24:31',
    'DefaultFontSize': 20,
    'FontId': 21,
    'Image': null,
    'ImageExtension': null,
    'ImageUrl': 'https://acc-hema-tool-images.s3-eu-central-1.amazonaws.com/fonts/font-21.svg',
    'LastModifiedBy': 'ad-acc\\emmhijdra',
    'MaximumFontSize': 100,
    'MinimumFontSize': 14,
    'Order': 8,
    'ProductTypeIds': [
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      17
    ],
    'Title': 'Bangers',
    'ImageLastModified': null,
    'LastModified': '08/21/2018 12:59:46',
    'FontFiles': [
      {
        'Id': 25,
        'FontId': 21,
        'FileName': 'BangersRegular.ttf',
        'FilePath': 'https://acc-hema-tool-fonts.s3-eu-central-1.amazonaws.com/21-BangersRegular.ttf',
        'Format': null,
        'MimeType': 'application/octet-stream',
        'CreatedOn': '05/08/2017 12:24:45'
      },
      {
        'Id': 26,
        'FontId': 21,
        'FileName': 'BangersRegular.woff',
        'FilePath': 'https://acc-hema-tool-fonts.s3-eu-central-1.amazonaws.com/21-BangersRegular.woff',
        'Format': null,
        'MimeType': 'application/font-woff',
        'CreatedOn': '05/08/2017 12:24:45'
      }
    ]
  };
}