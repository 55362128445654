import { DELETE, GET, POST, PUT } from './ApiService';
import appConfig from '../config/app-config';
import { getAllProductTypes } from './mocks/ProductServiceMock';

const baseUrl = `${appConfig.baseUri}/management/v1/ProductTypes`;
export default class ProductTypeService {

  static async getAll() {
    if (appConfig.isTest) {
      return {data: getAllProductTypes(), error: false };
    }

    return GET({
      url: baseUrl,
      token: true,
    });
  }

  static async getById(id) {
    return GET({
      url: `${baseUrl}/${id}`,
      token: true,
    });
  }

  static async save(request) {
    return POST({
      url: baseUrl,
      token: true,
      body: request,
    });
  }

  static async update(id, request) {
    return PUT({
      url: `${baseUrl}/${id}`,
      token: true,
      body: request,
    });
  }

  static async deleteById(id) {
    return DELETE({
      url: `${baseUrl}/${id}`,
      token: true,
    });
  }
}
