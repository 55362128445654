import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './authConfig';

import './styles/libs/bootstrap.min.css';
import './styles/pages/styles.css';

export class MsalSingleton {
  static msalInstance = new PublicClientApplication(msalConfig);

  static getMsalInstance() {
    return MsalSingleton.msalInstance;
  }

  init() {
    MsalSingleton.msalInstance.initialize().then(() => {
      ReactDOM.render(
        <MsalProvider instance={MsalSingleton.msalInstance}>
          <Router>
            <App />
          </Router>
        </MsalProvider>,
        document.getElementById('root')
      );
    });
  }
}

new MsalSingleton().init();
