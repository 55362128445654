export function appendIfExists(key, value, formData) {
  // eslint-disable-next-line
  if (value !== null && value !== undefined && value !== 'undefined' && value !== 'null' && value !== '') {
    formData.append(key, value);
  }
}

// YYYY-MM-DD hh:mm:ss
export function convertDateTime(date) {
  return `${date.getFullYear()}-${padTime(date.getMonth())}-${padTime(date.getDate())} ${padTime(date.getHours())}:${padTime(date.getMinutes())}:${padTime(date.getSeconds())}`;
}

export function getCurrentDateAndTime() {
  return convertDateTime(new Date());
}

function padTime(value) {
  return value.toString().substring(0, 2).padStart(2, '0');
}

export function getPreviewImage(url, index) {
  if (!url || !url.trim()) {
    return '';
  }

  if (url.startsWith('blob')) {
    return url;
  }

  if (!index || !index.trim()) {
    return url;
  }

  try {
    const date = new Date(index);
    return `${url}?${date.getTime()}`;
  } catch (error) {
    return `${url}?${index}`;
  }
}

export function getItemFormData(item) {
  const formData = new FormData();
  Object.keys(item).forEach(key => {
    if (item[key].values) {
      item[key].values.forEach(value => appendIfExists(`${key}[]`, value, formData));
    } else {
      appendIfExists(key, item[key].value, formData);
    }
  });
  return formData;
}

export async function readTextFileContents(file) {
  if (file) {
    let result = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        resolve(e.target.result);
      };
      reader.readAsText(file);
    });
    return result;
  }
  return '';
}
