import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ACTIVE_STATES } from '../../components/common/Enums';
import { ActiveDatePicker } from '../../components/common/ActiveDatePicker';
import { Routes } from '../../components/common/UrlUtils';
import StickerService from '../../services/StickerService';
import { validateFormWithCustomMessages } from '../../utils/validation';
import Spinner from './../../components/common/Spinner';
import ServerError from './../../components/states/ServerError';
import ProductTypeService from './../../services/ProductTypeService';
import CategoryService from './../../services/CategoryService';
import { TextValuePairOptions } from '../../components/common/TextValuePairOptions';
import { ValidationErrorMessage } from '../../components/common/VlidationErrorMessage';
import { getItemFormData, getPreviewImage } from '../../utils/dataUtils';
import { getLoggedUserName } from '../../utils/authUtils';
import { AlertMessage } from '../../utils/AlertMessage';
import { PageUtils } from '../PageUtils';

const StickerUpsertPage = () => {
  const FIELD_PLURAL = 'stickers';
  const history = useHistory();
  const { stickerId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const [isFormValid, setIsFormValid] = useState(isEdit());
  const [isServerError, setIsServerError] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    message: '',
    type: '',
  });
  const [item, setItem] = useState({
    Image: {
      value: null,
      errorMessage: '',
    },
    Title: {
      value: '',
      errorMessage: '',
    },
    ProductTypes: {
      values: [],
      errorMessage: '',
    },
    Categories: {
      values: [],
      errorMessage: '',
    },
    Active: {
      value: ACTIVE_STATES.Yes,
      errorMessage: '',
    },
    ActiveStartDate: {
      value: '',
      errorMessage: '',
    },
    ActiveEndDate: {
      value: '',
      errorMessage: '',
    },
    ImageUrl: {
      value: '',
      errorMessage: '',
    },
    LastModified: {
      value: '',
      errorMessage: '',
    },
    LastModifiedBy: {
      value: getLoggedUserName(),
      errorMessage: '',
    },
    Size: {
      value: '',
      errorMessage: ''
    }
  });

  const [data, setData] = useState({
    productTypes: [],
    categoryTypes: [],
  });

  useEffect(() => {
    if (isLoading) {
      fetchData();
      setIsLoading(false);
    }

    async function fetchData() {
      if (isEdit()) {
        const [result, productTypes, categories] = await Promise.all([
          StickerService.getById(stickerId),
          ProductTypeService.getAll(),
          CategoryService.getAll(),
        ]);
        populateServerData(result, productTypes, categories);
      } else {
        const [productTypes, categories] = await Promise.all([
          ProductTypeService.getAll(),
          CategoryService.getAll(),
        ]);
        populateServerData(null, productTypes, categories);
      }
    }

    function populateServerData(sticker, productTypes, categories) {
      if ((!isEdit() && sticker && sticker.error) || productTypes.error || categories.error) {
        setIsServerError(true);
      }

      if (isEdit()) {
        const copyItem = { ...item };
        Object.keys(item).forEach(key => {
          if (Array.isArray(sticker.data[key])) {
            copyItem[key].values = sticker.data[key];
          } else {
            copyItem[key].value = sticker.data[key];
          }
        });
        setItem(copyItem);
      }

      setData({ ...data, productTypes: productTypes.data, categoryTypes: categories.data });
    }

  }, [item, isLoading, isServerError, data, stickerId]);

  return (
    <>
      {PageUtils.getUpsertPageHeader(FIELD_PLURAL, isEdit(), Routes.sticker.index)}
      {AlertMessage.getContent(alertMessage)}
      {getEditFormContent()}
    </>
  );

  function isEdit() {
    return stickerId !== undefined;
  }

  function getEditFormContent() {
    if (isLoading) {
      return (<Spinner />);
    }

    if (isServerError) {
      return (<ServerError />);
    }

    return (
      <form onSubmit={onSubmitFormHandler} className='form-horizontal'>
        <fieldset disabled={isFormDisabled}>
          {getLastModifiedContent()}

          <div className='form-group'>
            {getStickerLabelOrImage()}
            <div className='col-sm-5'>
              <input
                type='file'
                name='Image'
                preview='ImageUrl'
                required={!isEdit()}
                accept='image/*'
                onInput={onInputChange}
                onBlur={onInputChange}
                onInvalid={onInvalidHandler} />
            </div>
          </div>

          <div className='form-group'>
            <label htmlFor='Title' className='control-label col-md-2'>Title</label>
            <div className='col-md-5'>
              <input
                type='text'
                name='Title'
                required={true}
                defaultValue={item.Title.value}
                className='form-control'
                onChange={onInputChange}
                onBlur={onInputChange} />
              <ValidationErrorMessage errorMessage={item.Title.errorMessage} />
            </div>
          </div>

          <div className='form-group'>
            <label className="control-label col-md-2" >Product type(s)</label>
            <div className='col-md-5'>
              <select className="form-control input-sm"
                name="ProductTypes"
                multiple={true}
                required={true}
                onChange={onInputChange}
                onBlur={onInputChange} >
                {getProductTypeOptionsContent()}
              </select>
              <ValidationErrorMessage errorMessage={item.ProductTypes.errorMessage} />
            </div>
          </div>

          <div className='form-group'>
            <label className="control-label col-md-2">Category</label>
            <div className='col-md-5'>
              <select className="form-control input-sm"
                multiple="multiple"
                name="Categories"
                required={true}
                onChange={onInputChange}
                onBlur={onInputChange} >
                {getCategoryOptionsContent()}
              </select>
              <ValidationErrorMessage errorMessage={item.Categories.errorMessage} />
            </div>
          </div>

          <ActiveDatePicker
            key={'active_date_picker'}
            activeState={item.Active.value}
            activeStartDate={item.ActiveStartDate.value}
            activeEndDate={item.ActiveEndDate.value}
            onActiveStateChange={onActiveStateChangeHandler}
            onDateChange={onDateChangeHandler}
          />

          <div className="form-group">
            <div className="col-md-offset-2 col-md-10">
              <button className="btn btn-primary" type="submit">Save</button>
            </div>
          </div>
        </fieldset>
        <input type="hidden" name="Size" value={item.Size.value} />
      </form>
    );
  }

  // Callback Handlers
  function onActiveStateChangeHandler(newState) {
    const copy = { ...item };
    copy.Active.value = newState;
    setItem(copy);
  }

  function getStickerLabelOrImage() {
    if (isEdit()) {
      return (<div className='col-sm-2 text-right'>
        <img
          className='img-responsive thumb thumb-hover'
          src={getPreviewImage(item.ImageUrl.value, item.LastModified.value)}
          alt='sticker' />
      </div>);
    }

    return (<label htmlFor='Image' className='control-label col-md-2'>Upload</label>);
  }

  function onDateChangeHandler(date, field) {
    const copy = { ...item };
    copy[field].value = date;
    setItem(copy);
  }

  function getLastModifiedContent() {
    if (!isEdit()) {
      return null;
    }

    return (
      <div className='form-group'>
        <label className='col-md-2 control-label'><strong>Last modified</strong></label>
        <div className='col-md-5'>
          <p className='form-control-static'>{item.LastModified.value} by {item.LastModifiedBy.value}</p>
        </div>
      </div>
    );
  }

  function onSubmitFormHandler(event) {
    event.preventDefault();

    if (!isFormValid) {
      setAlertMessage(AlertMessage.getInvalidFormMessage());
      return;
    }

    const formData = getItemFormData(item);
    if (isEdit()) {
      updateSticker(stickerId, formData);
    } else {
      saveSticker(formData);
    }
  }

  async function updateSticker(stickerId, formData) {
    setIsFormDisabled(true);
    const result = await StickerService.update(stickerId, formData);
    if (result.error) {
      setIsFormDisabled(false);
      setAlertMessage(setAlertMessage(AlertMessage.getSaveItemErrorMessage('sticker', item.Title.value, result)));
      return;
    }
    setAlertMessage(AlertMessage.getSaveItemSuccessMessage('sticker', item.Title.value));
    setTimeout(() => history.push(Routes.sticker.index), 1000);
  }

  async function saveSticker(formData) {
    setIsFormDisabled(true);
    const result = await StickerService.save(formData);
    if (result.error) {
      setIsFormDisabled(false);
      setAlertMessage(AlertMessage.getSaveItemErrorMessage('sticker', item.Title.value, result));
      return;
    }
    setAlertMessage(AlertMessage.getSaveItemSuccessMessage('sticker', item.Title.value));
    setTimeout(() => history.push(Routes.sticker.index), 1000);
  }

  function getProductTypeOptionsContent() {
    if (!data.productTypes.length) {
      return null;
    }

    return (
      <TextValuePairOptions
        items={data.productTypes.map(p => ({ value: p.ProductTypeId, text: p.Name }))}
        selectedValues={item.ProductTypes.values}
      />
    );
  }

  function getCategoryOptionsContent() {
    if (!data.categoryTypes.length) {
      return null;
    }

    return (
      <TextValuePairOptions
        items={data.categoryTypes.map(c => ({ value: c.CategoryId, text: c.Name }))}
        selectedValues={item.Categories.values}
      />
    );
  }

  function onInputChange(event) {
    const { result, valid } = validateFormWithCustomMessages(item, event);
    setIsFormValid(valid);

    if (event.target.type === 'file' && event.target.files && event.target.files.length) {
      const preview = event.target.getAttribute('preview');
      if (preview) {
        // Reset last modified because of the preview image
        result[preview].value = URL.createObjectURL(event.target.files[0]);
      }
    }

    setItem(result);
  };

  function onInvalidHandler(event) {
    // event.preventDefault();
  }

};

export default StickerUpsertPage;
