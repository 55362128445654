import React, { useEffect } from 'react';
import { Routes } from '../components/common/UrlUtils';

const Home = () => {

  useEffect(() => {
    document.title = 'Home Page';
  });

  return (
    <>
      <h2 className="page-header">Media</h2>
      <div className="row placeholders">
        {getFontMenuContent()}
        {getStickerMenuContent()}
        {getBackgroundMenuContent()}
      </div>
      <h2 className="page-header">Products</h2>
      <div className="row placeholders">
        {getProductMenuContent()}
        {getLayoutMenuContent()}
        {getMasterDataMenuContent()}
      </div>
    </>
  );

  function getFontMenuContent() {
    return getMenuItemContent(Routes.font, {
      title: 'Fonts', description: 'Add, edit or delete fonts', button: 'New font'
    }, '/assets/images/fonts.png');
  }

  function getStickerMenuContent() {
    return getMenuItemContent(Routes.sticker, {
      title: 'Stickers', description: 'Add, edit or delete stickers', button: 'New sticker'
    }, '/assets/images/stickers.png');
  }

  function getBackgroundMenuContent() {
    return getMenuItemContent(Routes.background, {
      title: 'Backgrounds', description: 'Add, edit or delete backgrounds', button: 'New background'
    }, '/assets/images/backgrounds.png');
  }

  function getProductMenuContent() {
    return getMenuItemContent(Routes.product, {
      title: 'Products', description: 'Add, edit or delete products', button: 'New product'
    }, '/assets/images/products.png');
  }

  function getLayoutMenuContent() {
    return getMenuItemContent(Routes.layout, {
      title: 'Templates', description: 'Add, edit or delete templates', button: 'New template'
    }, '/assets/images/templates.png');
  }

  function getMasterDataMenuContent() {
    return getMenuItemContent(Routes.masterdata, {
      title: 'Masterdata', description: 'Add, edit or delete masterdata', button: ''
    }, '/assets/images/mastertables.png');
  }

  function getMenuItemContent(routes, labels, imagePath) {
    return (
      <div className="col-xs-6 col-sm-3 placeholder">
        <a href={routes.index} target={'_self'} >
          <img alt="Generic placeholder thumbnail" className="img-thumbnail" height="150" src={imagePath} width="150" />
        </a>
        <h4><a href={routes.index} target={'_self'}>{labels.title}</a></h4>
        <ul className="list-unstyled">
          <li><span className="text-muted">{labels.description}</span></li>
          <li><span className="text-muted">&nbsp;</span></li>
          <li>
            {
              routes.create
                ?
                <a className="btn btn-primary btn-sm" href={routes.create} target={'_self'}>
                  <span aria-hidden="true" className="glyphicon glyphicon-plus"></span>
                  {labels.button}
                </a>
                :
                null
            }
          </li>
        </ul>
      </div>
    );
  }
};

export default Home;
